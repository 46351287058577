var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stock-movement-history-container"},[_c('div',{staticClass:"stock-movement-history-container__header"},[_c('span',{staticClass:"title"},[_vm._v(" Histórico de movimentações ")]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"search-container app-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchWord),expression:"searchWord"}],staticClass:"search-container__input",attrs:{"placeholder":"Pesquise uma movimentação","type":"text"},domProps:{"value":(_vm.searchWord)},on:{"input":function($event){if($event.target.composing)return;_vm.searchWord=$event.target.value}}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchWord.length > 0),expression:"searchWord.length > 0"}],staticClass:"search-container__clear clickable",on:{"click":_vm.clearSearch}},[_c('i',{staticClass:"fal fa-times-circle"})]),_c('button',{staticClass:"search-container__search",on:{"click":function($event){}}},[_c('i',{staticClass:"fas fa-search"})])])])]),(!_vm.loading)?_c('div',{staticClass:"stock-movement-history-container__content"},[_c('table',{staticClass:"stock-movement-history-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.displayArrayFilteredStockRegisters),function({
                        displayTextStockRegisterDate,
                        displayTextStockRegisterProductName,
                        displayTextStockRegisterAction,
                        displayTextStockRegisterField,
                        displayTextStockRegisterArea,
                        displayTextStockRegisterCalculatedTotalQuantity,
                        displayTextStockRegisterRealTotalQuantity,
                        displayTextStockRegisterBalance,
                        realTotalQuantityTextColor,
                        stockRegisterKey,
                        stockRegister
                    }){return _c('tr',{key:stockRegisterKey},[_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterDate)+" ")]),_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterProductName)+" ")]),_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterAction)+" ")]),_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterField)+" ")]),_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterArea)+" ")]),_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterCalculatedTotalQuantity)+" ")]),_c('td',{style:(realTotalQuantityTextColor)},[_vm._v(" "+_vm._s(displayTextStockRegisterRealTotalQuantity)+" ")]),_c('td',[_vm._v(" "+_vm._s(displayTextStockRegisterBalance)+" ")]),_c('td',[_c('div',{staticClass:"stock-movement-history-table-content__actions"},[_c('button',{staticClass:"btn btn--flat btn-primary--outlined",on:{"click":function($event){return _vm.openModalHistoryDetail(stockRegister)}}},[_c('i',{staticClass:"fas fa-eye"}),_c('span',[_vm._v(" Visualizar ")])])])])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasStockRegisters),expression:"!hasStockRegisters"}],staticClass:"no-content"},[_c('i',{staticClass:"fa fa-exclamation-circle fa-2x"}),_c('span',[_vm._v(" Não foram encontradas movimentações ")])])]):_c('div',{staticClass:"no-content"},[_c('span',{staticClass:"spinner-border"})]),(_vm.showModalHistoryDetail)?_c('modal-history-detail',{attrs:{"open":_vm.showModalHistoryDetail,"data":_vm.selectedStockRegister},on:{"close":_vm.closeModalHistoryDetail}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v(" Datas ")]),_c('th',[_vm._v(" Produto ")]),_c('th',[_vm._v(" Tipo ")]),_c('th',[_vm._v(" Talhão ")]),_c('th',[_vm._v(" Área (ha) ")]),_c('th',[_vm._v(" Quantidade calculada ")]),_c('th',[_vm._v(" Quantidade movimentada ")]),_c('th',[_vm._v(" Saldo ")]),_c('th',{staticClass:"stock-movement-history-table-header__actions"},[_vm._v(" Ações ")])])])
}]

export { render, staticRenderFns }