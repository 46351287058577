import Property from "../../api/Property";
import Crop from "../../api/Crop";

const state = {
  error: "",
  propertyState: {},
  propertiesState: [],
  propertySelected: {},
  propertyFields: [],
  fieldsPanel: [],
  crops: [],
  cropSelected: null,
  operators: [],
  operatorsDeactive: [],
  machineries: [],
  machineriesDeactive: [],
  products: [],
  notifications: [],
  numberNotificationUnread: 0,
  activities: [],
  lastActivities: [],
  myCropsList: []
};

const getters = {
  cropsList: state => state.myCropsList,
  getProperties: state => state.propertiesState,
  getPropertySelected: (state) => state.propertySelected,
  getCropSelected: (state) => state.cropSelected,
  getPropertyFields: (state) => state.propertyFields,
  getFieldsPanel: (state) => state.fieldsPanel,
  getNotifications: (state) => state.notifications,
  getNumberNotificationUnread: (state) => state.numberNotificationUnread,
  getAllActivities: (state) => state.activities,
  getProducts: (state) => state.products,
  getMachineries: (state) => state.machineries,
  getOperators: (state) => state.operators,
  getCrops: (state) => state.crops,
  getCropsDeactivated: (state) => state.cropsDeactivated,
  getLastActivities: (state) => state.lastActivities,
  getError: (state) => state.error,
};

const actions = {
  setMyCropsList({ commit }, myCropsList) {
    commit("setCropsList", myCropsList)
  },
  async listFields({ commit }, propertyId) {
    await Property.getPropertyFields(propertyId)
      .then((response) => {
        commit("setPropertyFields", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listFieldsByCrop({ commit }, cropId) {
    const auxFields = [];

    await Crop.getFieldsByCropAndProperty(cropId)
      .then((response) => {
        response.data.data.map((res) => {
          if (res.field !== null) {
            auxFields.push(res.field);
          }
        });
        const removedDuplicated = auxFields.reduce((unique, o) => {
          if (!unique.some((obj) => obj._id === o._id)) {
            if (o.active === true) {
              unique.push(o);
            }
          }
          return unique;
        }, []);
        commit("setFieldsPanel", removedDuplicated);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listCrops({ state, commit, dispatch }, propertyId) {
    await Property.getPropertyCrops(propertyId)
      .then((response) => {

        let data = response.data.data.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });

        let deactivateds = data.filter((crop) => crop.active === false);
        commit("setPropertyCropsDeactivated", deactivateds);
        data = data.filter((crop) => crop.active === true);
        
        let crops = data.sort(function (a, b) {
          return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
        });
        let sizeCrops = crops.length;

        if (state.cropSelected === null) {
          if (sizeCrops === 1) {
            dispatch("listCropSelected", crops[0]._id);
            /* commit("setCropsList", crops[0]._id) */
          }
          else {
            dispatch("listCropSelected", crops[sizeCrops - 1]._id);
            /* commit("setCropsList", crops[sizeCrops - 1]._id) */
          }
        }

        commit("setPropertyCrops", data);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listOperators({ commit }, propertyId) {
    await Property.getPropertyOperators(propertyId)
      .then((response) => {
        commit("setPropertyOperators", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listMachineries({ commit }, propertyId) {
    const response = await Property.getPropertyMachineries(propertyId);
    if (response.status === 200) {
      commit("setPropertyMachineries", response.data.data.filter(item => item.active));
    }
    return response;
  },

  async listProducts({ commit }, propertyId) {
    await Property.getPropertyProducts(propertyId)
      .then((response) => {
        commit("setPropertyProducts", response.data.data.filter(item => item.active));
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listPropertyNotifications({ commit }, propertyId) {
    let countUnread = 0;
    await Property.getPropertyNotifications(propertyId)
      .then((response) => {
        commit("setPropertyNotifications", response.data.data);
        response.data.data.map((res) => {
          if (res.read === false) countUnread += 1;
        });
        commit("setNumberNotificationUnread", countUnread);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  markNotificationAsReadOrUnread({ commit, dispatch }, payload) {
    Property.markNotificationAsReadOrUnread(
      payload.notification._id,
      !payload.notification.read
    )
      .then(() => {
        dispatch("listPropertyNotifications", payload.propertySelectedId);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  listAllPropertyActivities({ commit }, propertyId) {
    Property.getAllPropertyActivities(propertyId)
      .then((response) => {
        commit("setPropertyActivities", response.data.data);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listPropertyLastActivities({ commit }, data) {
    await Property.getPropertyLastActivities(data.propertyId, data.cropId)
      .then((response) => {
        commit("setLastActivities", response.data.data.activities);
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listPropertySelected({ commit, dispatch }, propertyId) {
    await Property.getProperty(propertyId)
      .then(async (response) => {
        let data = response.data.data;
        data.latitude = data.coordinates.latitude;
        data.longitude = data.coordinates.longitude;
        
        await commit("setPropertySelected", data);
        await dispatch("listProperty", {accountId: data.account._id})
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async listCropSelected({ commit }, cropId) {
    let arr = []
    await Crop.get(cropId)
      .then(async (response) => {
        let data = response.data.data;
        await commit("setCropSelected", data);
        arr.push(data)
        await commit("setCropsList", arr)
      })
      .catch((error) => {
        commit("setError", error);
      });
  },

  async clearCropSelected({ commit }) {
    await commit("setCropSelected", {});
  },

  async listProperty({ commit }, { accountId, userId}) {
    const response = await Property.getAll(accountId, userId)
    commit("setProperty", response.data.data)
  },

  async insertProperty({ commit }, property) {
    const response = await Property.createProperty(property)
    return response
  },

  async updateProperty({ commit }, property) {
    const response = await Property.updateProperty(property)
    return response
  },

  clearError({ commit }) {
    commit("setPropertyFields", []);
    commit('setFieldsPanel', [])
    commit("setPropertyCrops", []);
    commit("setPropertyCropsDeactivated", []);
    commit("setPropertyOperators", []);
    commit('setPropertyMachineriesl', [])
    commit('setPropertyProducts', [])
    commit("setPropertyNotifications", []);
    commit('setPropertyActivities', [])
    commit("setCropSelected", null);
    commit('setPropertySelected', {})
    commit('setProperty', [])
  },
};

const mutations = {
  setCropsList: (state, myCropsList) => state.myCropsList = myCropsList,
  setPropertyFields: (state, propertyFields) =>
    (state.propertyFields = propertyFields),
  setFieldsPanel: (state, fieldsPanel) => (state.fieldsPanel = fieldsPanel),
  setPropertyCrops: (state, crops) => (state.crops = crops),
  setPropertyCropsDeactivated: (state, cropsDeactivated) => (state.cropsDeactivated = cropsDeactivated),
  setPropertyOperators: (state, operators) => (state.operators = operators),
  setPropertyMachineries: (state, machineries) =>
    (state.machineries = machineries),
  setPropertyProducts: (state, products) => (state.products = products),
  setPropertyNotifications: (state, notifications) =>
    (state.notifications = notifications),
  setNumberNotificationUnread: (state, numberNotificationUnread) =>
    (state.numberNotificationUnread = numberNotificationUnread),
  setPropertyActivities: (state, activities) => (state.activities = activities),
  setLastActivities: (state, lastActivities) =>
    (state.lastActivities = lastActivities),
  setPropertySelected: (state, propertySelected) =>
    (state.propertySelected = propertySelected),
  setCropSelected: (state, cropSelected) => (state.cropSelected = cropSelected),
  setError: (state, error) => {
    state.error = error;
  },
  setProperty: (state, properties) => (state.propertiesState = properties)
};

export default { state, getters, actions, mutations, namespaced: true };
