import { render, staticRenderFns } from "./ModalSeedTreatment.vue?vue&type=template&id=a553d6f6&scoped=true"
import script from "./ModalSeedTreatment.vue?vue&type=script&lang=js"
export * from "./ModalSeedTreatment.vue?vue&type=script&lang=js"
import style0 from "./ModalSeedTreatment.vue?vue&type=style&index=0&id=a553d6f6&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a553d6f6",
  null
  
)

export default component.exports