<template>
    <div>
        <div class="container-date-culture-phase">
            <div class="flex-container">
                <div class="container-lavel-input-harvest">

                    <label class="app-label-harvest"> {{ onlyDate ? 'Data' : 'Data e Hora' }} </label>
                    <input
                        class="form-control-harvest"
                        type="date"
                        v-model="selectedDate"
                    >
                </div>
                <input
                    class="form-control"
                    type="time"
                    v-model="selectedHour"
                    v-if="!onlyDate"
                >
                <culture-phase-selector-harvest
                    v-model="culturePhaseId"
                />
                <button
                    class="btn btn--flat btn--rounded"
                    @click="addDateAndHour"
                >
                    <i class="material-icons"> add_circle_outline </i>
                </button>
            </div>
        </div>
        <div class="flex-container-wrap">
            <div
                v-for="(date, dateIndex) in selectedDates"
                :key="`${uuid}_date_${dateIndex}`"
                class="chip"
            >

                <span v-if="onlyDate"> {{ date.dateHour | formatDate }} {{date.culturePhaseAbbreviation}} </span>
                <span v-else> {{ date.dateHour | formatDateTime }} {{date.culturePhaseAbbreviation}} </span>
                <i class="chip-action material-icons" @click="removeDate(dateIndex)">close</i>
            </div>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import CulturePhaseSelectorHarvest from '@/components/activities/components/culture-phases/CulturePhaseSelectorHarvest.vue'

export default {
    name: "DateSelector",
    props: {
        uuid: {
            type: String
        },
        value: {
            type: Array
        },
        onlyDate: {
            type: Boolean,
            default: true
        }
    },
    components: {
        CulturePhaseSelectorHarvest
    },
    data() {
        return {
            selectedDate: '',
            selectedHour: '',
            selectedDates: [],
            culturePhaseId: '',
            culturePhaseAbbreviation: ''
        };
    },
    watch: {
        value() {
            if (this.inputDateIsDifferentFromSelected()) {
                this.selectedDates = this.formattedInputDates()
            }
        },
        selectedDates() {
            this.emitFormattedSelectedDates()
        }
    },
    computed: {
        ...mapGetters("culture-phases", [ "getCulturePhases" ]),
    },
    methods: {
        joinDateAndHour(date, hour) {
            if (!hour) {
                return `${date}`
            } else {
                return `${date}T${hour}Z`
            }
        },
        addDateAndHour() {
            if (!this.selectedDate) {
                return this.$vToastify.info('Selecione uma data', "Atenção!")
            }

            const selectedDate = this.joinDateAndHour(this.selectedDate, this.selectedHour);
            const isRepeatedDate = this.selectedDates.find(date => {
                return new Date(date.dateHour).toISOString() == new Date(selectedDate).toISOString()
            })
            if (isRepeatedDate) {
                return this.$vToastify.info('Essa data já foi selecionada', "Atenção!")
            }

            let selectedCulturePhaseAbbreviation = ''
            this.getCulturePhases.map((culture)=>{
                if(culture._id === this.culturePhaseId){
                    selectedCulturePhaseAbbreviation = culture.abbreviation
                }
            })

            this.selectedDates.push({
                dateHour: selectedDate,
                done: false,
                culturePhase: this.culturePhaseId,
                culturePhaseAbbreviation: selectedCulturePhaseAbbreviation
            })
        },
        inputDateIsDifferentFromSelected() {
            const formattedInputDates = this.formattedInputDates();

            const hasDifferentArraySize = this.selectedDates.length != formattedInputDates.length

            return hasDifferentArraySize
        },
        removeDate(index) {
            this.selectedDates.splice(index, 1);
        },
        formatInputDate(date) {
            return date
        },
        formattedInputDates() {
            return this.value.map(date => this.formatInputDate(date))
        },
        formatOutputDate(date) {
            return date
        },
        formattedOutputDates() {
            return this.selectedDates.map(date => this.formatOutputDate(date))
        },
        emitFormattedSelectedDates() {
            this.$emit('input', this.formattedOutputDates())
        }
    },
    mounted() {
        this.selectedProducts = []
    }
};
</script>
<style lang="sass" scoped>
.input-filter
    width: 100%
    padding: 7px

.flex-container
    margin-right: 100px
    height: 50px
    
.container-date-culture-phase
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between

.app-label-harvest
    color: $color-blue-darken-2
    font-weight: bold
    display: inline-block
    display: block
    margin: 0

.form-control-harvest
    display:block
    width:100%
    height:calc(1.5em + .75rem + 2px)
    padding:.375rem .75rem
    font-size:1rem
    font-weight:400
    line-height:1.5
    color:#495057
    background-color:#fff
    background-clip:padding-box
    border:1px solid #ced4da
    border-radius:.25rem    
    margin-left: 0 

.container-lavel-input-harvest
    height: 200px
    margin-top: 115px
</style>
