<template>
    <div>
        <Modal :open="open" :loading="loadingData" @close="$emit('close')">
            <div class="app-modal-header" slot="header">
                <div class="icon-harvest"/>
                <span v-if="isUpdateModal()">
                    Edição de Colheita
                </span>
                <span v-else>
                    Nova Colheita
                </span>
            </div>
            <div class="app-modal-body" slot="body">
                <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        :style="`width: ${getPercentPage()}%`"
                        :aria-valuenow="getPercentPage()"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    />
                    <div class="progress-counter" :style="`margin-left: ${getPercentPage(true)}%`">
                        {{ page }}/{{ pages }}
                    </div>
                </div>
                <div v-show="page == 1">
                    <div>
                        <label
                            for="crop"
                            class="app-label subarea-selector__title"
                        >
                            Safra:
                        </label>
                        <span
                            id="crop"
                            class="subarea-selector__text"
                        >
                            {{ this.getCropSelected.name }}
                        </span>
                    </div>
                    <div class="content">
                        <subarea-selector
                            :uuid="isUpdateModal() ? 'harvest_edit_subarea' : 'harvest_create_subarea'"
                            v-model="selectedSubareas"
                            v-show="!isUpdateModal()"
                            :editSizeArea="false"
                        />
                    </div>
                </div>
                <div class="content" v-show="page == 2">
                    <div>
                        <label
                            class="app-label"
                            for="title"
                        >
                            Título
                        </label>
                        <input
                            class="app-input"
                            type="text"
                            id="title"
                            v-model="harvestData.title"
                            placeholder="Ex: 1ª Pulverização, 2ª Fungicida + 1ª Fertilizante"
                        >
                    </div>
                    <div>
                        <date-selector-harvest
                            :uuid="isUpdateModal() ? 'harvest_edit_date' : 'harvest_create_date' "
                            v-model="harvestData.date"
                        />
                    </div>
                    <div>
                        <operator-selector
                            :uuid="isUpdateModal() ? 'harvest_edit_operator' : 'harvest_create_operator' "
                            v-model="harvestData.operators"
                        />
                    </div>
                </div>
                <div class="content" v-show="page == 3">
                    <input-number-selectable id="productivity" label="Produtividade alcançada" placeholder="Ex: 5"
                        :items="['Sacas por hectare(Sc/ha)', 'Tonelada por hectare(Ton/ha)','Quilos por hectare(Kg/ha)']"
                        :item-selected="lengthenUnity(harvestData.productivity.unity)"
                        :value="harvestData.productivity.value"
                        @input="setValueUnity($event, harvestData, 'productivity')"
                    />
                    <input-number-selectable id="production" label="Produção" placeholder="Ex: 5"
                        :items="['Sacas por talhão(Sc)', 'Tonelada por talhão(Ton)','Quilos por talhão(Kg)']"
                        :item-selected="lengthenUnity(harvestData.production.unity)"
                        :value="harvestData.production.value"
                        @input="setValueUnity($event, harvestData, 'production')"
                    />
                    <input-number id="humidity" label="Umidade ao colher (%)" placeholder="Ex: 10"
                        :data="harvestData.humidityGrains.value"
                        @input="harvestData.humidityGrains.value = $event"/>
                    <div>
                        <machinery-selector
                            :uuid="isUpdateModal() ? 'harvest_edit_machinery' : 'harvest_create_machinery' "
                            v-model="harvestData.machineries"
                        />
                    </div>
                    <div>
                        <label class="app-label" for="obs">Observações</label>
                        <textarea class="app-textarea" v-model="harvestData.notes" rows="4"
                        id="obs"/>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer" slot="footer">
                <button class="btn btn-primary--outlined btn--flat" v-show="!saving" :disabled="this.page == 1"
                        @click="previousPage">
                    <i class="far fa-arrow-alt-circle-left"/>
                    <span>Voltar</span>
                </button>
                <button class="btn btn-primary btn--flat" v-show="!saving" :disabled="loadingData" v-if="page < pages" @click="nextPage">
                    <span>Proximo</span>
                    <i class="far fa-arrow-alt-circle-right"></i>
                </button>
                <button class="btn btn-primary btn--flat" v-show="!saving" v-else @click="saveHarvest">
                    <span>Salvar</span>
                    <i class="fas fa-save"/>
                </button>
                <div v-if="saving" style="width: 200px; display: flex; justify-content: center">
                    <div style="display: flex; justify-content: center">
                        <div style="margin-bottom: 6px;" class="spinner-border text-success" role="status">
                            <span class="sr-only">Saving...</span>
                        </div>
                    </div>
                    <div style="margin-left: 10px; margin-top: 4px">Salvando</div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Droplist from '@/components/systemUI/Droplist.vue'
import InputNumber from '@/components/commons/InputNumber'
import InputNumberSelectable from '@/components/systemUI/InputNumberSelectable.vue'
import Modal from '@/components/systemUI/Modal'
import CheckboxGroup from '@/components/commons/CheckboxGroup.vue'
import MachinerySelector from '@/components/activities/components/machineries/MachinerySelector.vue'
import SubareaSelector from '@/components/activities/components/subareas/SubareaSelector.vue'
import DateSelectorHarvest from '@/components/activities/components/dates/DateSelectorHarvest.vue'
import OperatorSelector from '@/components/activities/components/operators/OperatorSelector.vue'
import CulturePhaseSelector from '@/components/activities/components/culture-phases/CulturePhaseSelector.vue'
import subareaApi from '@/api/Subarea'
import _ from 'lodash';


export default {
    name: 'ModalHarvest',
    props: {
        data: Object,
        event: Object,
        open: Boolean
    },
    components: {
        Droplist,
        InputNumber,
        InputNumberSelectable,
        Modal,
        SubareaSelector,
        MachinerySelector,
        DateSelectorHarvest,
        OperatorSelector,
        CulturePhaseSelector
    },
    data() {
        return {
            loadingData: false,
            saving: false,
            page: 1,
            pages: 3,
            selectedSubareas: [],
            harvestData: {
                date: [],
                subareaId: "",
                title: "",
                operators: [],
                machineries: [],
                notes: "",
                culturePhaseId: null,
                humidityGrains: { value: null, unity: "%" },
                productivity: { value: null, unity: "Sc/ha" },
                production: { value: null, unity: "Sc" }
            }
        };
    },
    watch: {
        async open() {
            this.clearData()
            if (this.open) {
                await this.loadData();
                if (this.isUpdateModal()) {
                    this.copyData();
                }
            }
        }
    },
    computed: {
        ...mapGetters('operators', ['operatorsState']),
        ...mapGetters('machineries', ['machineriesState']),
        ...mapGetters('subareas', ['getSubareas']),
        ...mapGetters("property", [ "getPropertySelected", "getCropSelected" ])
    },
    methods: {
        ...mapActions("activities", ['postHarvest', 'updateHarvest']),
        ...mapActions("culture-phases", ["findCulturePhases"]),
        ...mapActions('operators', ['setOperators', 'getOperators']),
        ...mapActions('machineries', ['setMachineries', 'getMachineries']),
        ...mapActions('subareas', ['setSubareas', 'findSubareas']),
        ...mapActions("plannings", ["getPlannings"]),
        async loadData() {
            this.loadingData = true;

            const propertyId = this.getPropertySelected._id
            const cropId = this.getCropSelected._id

            await this.getOperators({ propertyId });
            await this.getMachineries({ propertyId });
            await this.findSubareas({ propertyId, cropId });
            await this.findCulturePhases();

            this.loadingData = false;
        },
        setValueUnity(event, harvestData, key) {
            harvestData[key].value = event.input
            harvestData[key].unity = this.shortenUnity(event.select)
            return harvestData
        },
        shortenUnity(unity) {
            const unities = {
                'Sacas por hectare(Sc/ha)': 'Sc/ha',
                'Tonelada por hectare(Ton/ha)': 'Ton/ha',
                'Quilos por hectare(Kg/ha)': 'Kg/ha',
                'Sacas por talhão(Sc)': 'Sc',
                'Tonelada por talhão(Ton)': 'Ton',
                'Quilos por talhão(Kg)': 'Kg'
            };
            return unities[unity];
        },
        lengthenUnity(unity) {
            const unities = {
                'Sc/ha': 'Sacas por hectare(Sc/ha)',
                'Ton/ha': 'Tonelada por hectare(Ton/ha)',
                'Kg/ha': 'Quilos por hectare(Kg/ha)',
                'Sc': 'Sacas por talhão(Sc)',
                'Ton': 'Tonelada por talhão(Ton)',
                'Kg': 'Quilos por talhão(Kg)'
            };
            return unities[unity];
        },
        hasSubareasState() {
            return this.getSubareas && this.getSubareas.length > 0;
        },
        hasSelectedSubareas() {
            return this.selectedSubareas && this.selectedSubareas.length > 0;
        },
        hasSelectedDates() {
            return this.harvestData.date && this.harvestData.date.length > 0;
        },
        hasSelectedCrop() {
            return this.harvestData.subarea && this.harvestData.subarea.crop;
        },
        isUpdateModal() {
            return this.data && this.data._id
        },
        nextPage() {
            if (this.page == 1) {
                if (!this.hasSelectedSubareas() && !this.isUpdateModal()) {
                    return this.$vToastify.info("Selecione uma safra e um talhão", "Atenção!")
                }
            }

            if (this.page === 2) {
                if (!this.hasSelectedDates()) {
                    return this.$vToastify.info("Selecione pelo menos uma data", "Atenção!")
                }
            }

            if (this.page < this.pages) {
                this.page++;
            }
        },
        previousPage() {
            if (this.page > 1) {
                this.page--;
            }
        },
        getPercentPage(counter = false) {
            if (!counter) {
                return (this.page / this.pages) * 100;
            } else {
                if (this.page == this.pages) {
                    return (this.page / this.pages) * 100 - 4;
                } else {
                    return (this.page / this.pages) * 100 - 2;
                }
            }
        },
        async saveHarvest() {
            this.saving = true;
            if (this.isUpdateModal()) {
                await this.updateHarvest({ harvestId: this.harvestData._id, harvest: this.harvestData })
                    .then(() => {
                        this.saving = false;
                        this.$vToastify.success("Atividade salva com sucesso!", "Colheita")
                        this.$emit('done')
                    })
                    .catch(() => {
                        this.saving = false;
                        this.$vToastify.error("Não foi possivel criar a atividade, tente novamente!", "Colheita")
                    });

            } else {
                const requests = this.selectedSubareas.map(subareaId => {
                    this.harvestData.subareaId = subareaId
                    return this.postHarvest(this.harvestData)
                })
                await Promise.all(requests)
                    .then(() => {
                        this.saving = false;
                        this.$vToastify.success("Atividade salva com sucesso!", "Colheita")
                        this.$emit('done')
                    })
                    .catch(() => {
                        this.saving = false;
                        this.$vToastify.error("Não foi possivel criar a atividade, tente novamente!", "Colheita")
                    });
            }
        },
        copyData() {
            const subareaId = this.returnIfExists(this.data, "subarea._id") || null
            const culturePhaseId = this.returnIfExists(this.data, "culturePhase._id") || null

            const dataClone = _.cloneDeep(this.data)
            this.harvestData = {
                _id: dataClone._id,
                date: dataClone.date,
                subareaId: subareaId,
                title: dataClone.title,
                culturePhaseId: culturePhaseId,
                operators: dataClone.operators,
                machineries: dataClone.machineries,
                humidityGrains: dataClone.humidityGrains,
                productivity: dataClone.productivity,
                production: dataClone.production,
                notes: dataClone.notes
            }
        },
        clearData() {
            this.setSubareas([]);
            this.setOperators([]);
            this.setMachineries([]);
            Object.assign(this.$data, this.$options.data.apply(this));
        }
    },
    async mounted() {
        this.clearData()
        if (this.open) {
            await this.loadData();
            if (this.isUpdateModal()) {
                this.copyData();
            }
        }
    }
};
</script>

<style scoped lang="sass">
.icon-harvest
    mask: url("/img/icons/svg/harvest.svg") no-repeat 50% 50%
    @include icon-mask-sm

.content
    display: flex
    flex-direction: column
    grid-gap: 20px

    .description
        font-size: 16px

    .loading
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        color: $color-blue

    .label-description
        margin-left: 6px
        font-size: 10px

    .box
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-start
        flex-wrap: wrap
        width: 100%

    .box-c
        display: flex
        flex-direction: row
        height: 30px
        width: 180px
        margin-bottom: 10px

.checkbox-group-label
    width: 100%
    padding: 4px


.checkbox-group
    display: flex
    width: 100%
    flex-wrap: wrap

    &__item
        width: 33.333%
        display: flex
        align-items: center
        padding: 6px 6px 6px 0px

</style>
