<template>
    <div>
        <Modal
            :open="open"
            :loading="false"
            @close="$emit('close')"
        >
            <div
                class="app-modal-header"
                slot="header"
            >
                <div
                    :style="`background: ${event.extendedProps.headerBigColor}`"
                    class="status-big"
                />
                <span>{{ displayTextActivityFieldName }}</span>
                <div
                    :class="activityIcon"
                />
            </div>
            <div class="app-modal-body" slot="body">
                <div class="row">
                    <div class="col-md-12 card color-1">
                        <div class="row">
                            <div class="col-md-3">
                                <strong>
                                    Data/Hora
                                </strong>
                                <div class="group">
                                    <div
                                        :style="`background: ${event.extendedProps.headerBigColor}`"
                                        class="status-small"
                                    ></div>
                                    <p>
                                        {{ displayTextActivityDate }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <strong>
                                    Atividade
                                </strong>
                                <p>
                                    {{ displayTextActivityTitle }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <div class="group">
                                    <strong>
                                        Talhão
                                    </strong>
                                    <div class="icon-fields"/>
                                </div>
                                <p>
                                    {{ displayTextActivityFieldName }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <strong>
                                    Sub-áreas
                                </strong>
                                <p>
                                    {{ displayTextActivitySubareaName }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 card color-2">
                        <div class="row">
                            <div
                                v-show="activityType !== 'harvest'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Produtos
                                    </strong>
                                    <div class="icon-product"/>
                                </div>
                                <ul v-if="activityType != 'harvest'">
                                    <li v-if="!hasStockProducts">
                                        -
                                    </li>
                                    <li
                                        v-for="stockProduct in displayArrayActivityStockProducts"
                                        :key="stockProduct._id"
                                    >
                                        <span
                                            v-if="!stockProduct.isApproved && !stockProduct.hasQuantityAvailableInStock"
                                            class="text-warning mr-2"
                                        >
                                            <i
                                                class="fas fa-exclamation-triangle"
                                                data-toggle="tooltip"
                                                data-placement="right"
                                                title="Quantidade indisponível no estoque."
                                            />
                                        </span>
                                        <span>
                                            {{ stockProduct.displayTextStockProduct }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-3">
                                <div class="group">
                                    <strong>
                                        Maquinários
                                    </strong>
                                    <div class="icon-machinery"/>
                                </div>
                                <ul>
                                    <li v-if="!hasMachineries">
                                        -
                                    </li>
                                    <li
                                        v-for="machinery in displayArrayActivityMachineries"
                                        :key="machinery._id"
                                    >
                                        {{ machinery.displayTextMachinery }}
                                    </li>
                                </ul>
                            </div>
                            <div
                                v-show="activityType === 'planting'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Produtividade esperada
                                    </strong>
                                </div>
                                <div>
                                    <span>
                                        {{ displayTextActivityExpectedProductivity }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-show="activityType === 'harvest'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Produtividade
                                    </strong>
                                </div>
                                <div>
                                    <span>
                                        {{ displayTextActivityProductivity }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-show="activityType === 'harvest'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Produção
                                    </strong>
                                </div>
                                <div>
                                    <span>
                                        {{ displayTextActivityProduction }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-show="activityType === 'harvest'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Umidade (%)
                                    </strong>
                                </div>
                                <div class="group">
                                    <span>
                                        {{ displayTextActivityHumidityGrains }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-show="activityType === 'pulverization'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Vazão (L/ha)
                                    </strong>
                                </div>
                                <div class="group">
                                    <span>
                                        {{ displayTextActivityFlowRate }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-show="this.data.planning.culturePhase"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Estádio da cultura
                                    </strong>
                                </div>
                                <div class="group">
                                    <span>
                                        {{ displayTextActivityCulturePhase }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-show="activityType === 'seedTreatment'"
                                class="col-md-3"
                            >
                                <div class="group">
                                    <strong>
                                        Semente tratada
                                    </strong>
                                </div>
                                <div class="group">
                                    <span>
                                        {{ displayTextActivitySeedToTreat }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 card">
                        <div class="row">
                            <div class="col-md-3">
                                <strong>Operadores</strong>
                                <ul>
                                    <li v-if="!hasOperators">
                                        -
                                    </li>
                                    <li
                                        v-else
                                        v-for="operator in displayArrayActivityOperators"
                                        :key="operator._id"
                                    >
                                        {{ operator.displayTextOperator }}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-9">
                                <div class="group">
                                    <strong>
                                        Observações
                                    </strong>
                                </div>
                                <div class="group">
                                    <span>
                                        {{ displayTextActivityNotes }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer" slot="footer">
                <button
                    class="btn btn-primary btn--flat"
                    @click="completeActivity"
                    v-if="!isActivityDone && !loading && !isLoadingDeactivate"
                >
                    <i class="fa fa-check" />
                    <span>Concluir Atividade</span>
                </button>
                <button
                    class="btn btn--outlined"
                    @click="updateOneActivity"
                    v-if="!loading && !isLoadingDeactivate"
                >
                    <i class="fa fa-edit" />
                    <span>Editar ou Reagendar apenas esta data</span>
                </button>
                <button
                    class="btn btn--outlined"
                    @click="updateActivity"
                    v-if="!loading && !isLoadingDeactivate && this.data.planning.type !== 'harvest'"
                >
                    <i class="fa fa-edit" />
                    <span>Editar ou Reagendar em todas as datas</span>
                </button>
                <button
                    class="btn btn--flat btn--danger"
                    @click="deactivateActivity"
                    v-if="isFarmerAdmin && !loading && !isLoadingDeactivate"
                >
                    <i class="fa fa-trash" />
                    <span>Desativar</span>
                </button>
                <div
                    v-if="loading || isLoadingDeactivate"
                    style="width: 198px; display: flex; justify-content: center"
                >
                    <div
                        style="margin-bottom: 6px"
                        class="spinner-border text-success"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </Modal>
        <modal-edit-one-pulverization
            v-if="showModalEditOnePulverization"
            :open="showModalEditOnePulverization"
            @done="emitDoneAndClose"
            @close="closeModalEditOnePulverization"
            :data="pulverizationData"
            :updateDate="updateOnlyOneActivityDate"
        />
        <modal-pulverization
            v-if="showModalPulverization"
            :open="showModalPulverization"
            @done="emitDoneAndClose"
            @close="closeModalPulverization"
            :data="pulverizationData"
        />
        <modal-planting
            v-if="showModalPlanting"
            :open="showModalPlanting"
            @done="emitDoneAndClose"
            @close="closeModalPlanting"
            :data="plantingData"
        />
        <modal-edit-one-planting
            v-if="showModalEditOnePlanting"
            :open="showModalEditOnePlanting"
            @done="emitDoneAndClose"
            @close="closeModalEditOnePlanting"
            :data="plantingData"
            :updateDate="updateOnlyOneActivityDate"
        />
        <modal-harvest
            v-if="showModalHarvest"
            :open="showModalHarvest"
            @done="emitDoneAndClose"
            @close="closeModalHarvest"
            :data="harvestData"
        />
        <modal-edit-one-harvest
            v-if="showModalEditOneHarvest"
            :open="showModalEditOneHarvest"
            @done="emitDoneAndClose"
            @close="closeModalEditOneHarvest"
            :data="harvestData"
            :updateDate="updateOnlyOneActivityDate"
        />
        <modal-soil-preparation
            v-if="showModalSoilPreparation"
            :open="showModalSoilPreparation"
            @done="emitDoneAndClose"
            @close="closeModalSoilPreparation"
            :data="soilPreparationData"
        />
        <modal-edit-one-soil-preparation
            v-if="showModalEditOneSoilPreparation"
            :open="showModalEditOneSoilPreparation"
            @done="emitDoneAndClose"
            @close="closeModalEditOneSoilPreparation"
            :data="soilPreparationData"
            :updateDate="updateOnlyOneActivityDate"
        />
        <modal-seed-treatment
            v-if="showModalSeedTreatment"
            :open="showModalSeedTreatment"
            @done="emitDoneAndClose"
            @close="closeModalSeedTreatment"
            :data="seedTreatmentData"
        />
        <modal-edit-one-seed-treatment
            v-if="showModalEditOneSeedTreatment"
            :open="showModalEditOneSeedTreatment"
            @done="emitDoneAndClose"
            @close="closeModalEditOneSeedTreatment"
            :data="seedTreatmentData"
            :updateDate="updateOnlyOneActivityDate"
        />
        <modal-edit-one-fertilization
            v-if="showModalEditOneFertilization"
            :open="showModalEditOneFertilization"
            @done="emitDoneAndClose"
            @close="closeModalEditOneFertilization"
            :data="fertilizationData"
            :updateDate="updateOnlyOneActivityDate"
        />
        <modal-fertilization
            v-if="showModalFertilization"
            :open="showModalFertilization"
            @done="emitDoneAndClose"
            @close="closeModalFertilization"
            :data="fertilizationData"
        />
        <modal-other-activity
            v-if="showModalOtherActivity"
            :open="showModalOtherActivity"
            @done="emitDoneAndClose"
            @close="closeModalOtherActivity"
            :data="otherActivityData"
        />
        <modal-edit-one-other-activity
            v-if="showModalEditOneOtherActivity"
            :open="showModalEditOneOtherActivity"
            @done="emitDoneAndClose"
            @close="closeModalEditOneOtherActivity"
            :data="otherActivityData"
            :updateDate="updateOnlyOneActivityDate"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/systemUI/Modal";
import ModalPulverization from "@/components/activities/ModalPulverization";
import ModalEditOnePulverization from "@/components/activities/ModalEditOnePulverization";
import ModalHarvest from "@/components/activities/ModalHarvest";
import ModalEditOneHarvest from "@/components/activities/ModalEditOneHarvest";
import ModalPlanting from "@/components/activities/ModalPlanting";
import ModalEditOnePlanting from "@/components/activities/ModalEditOnePlanting";
import ModalSoilPreparation from "@/components/activities/ModalSoilPreparation";
import ModalEditOneSoilPreparation from "@/components/activities/ModalEditOneSoilPreparation";
import ModalSeedTreatment from "@/components/activities/ModalSeedTreatment";
import ModalEditOneSeedTreatment from "@/components/activities/ModalEditOneSeedTreatment";
import ModalFertilization from "@/components/activities/ModalFertilization";
import ModalEditOneFertilization from "@/components/activities/ModalEditOneFertilization";
import ModalOtherActivity from "@/components/activities/ModalOtherActivity";
import ModalEditOneOtherActivity from "@/components/activities/ModalEditOneOtherActivity";

export default {
    name: "ModalActivityResume",
    props: {
        data: Object,
        event: Object,
        open: Boolean,
    },
    components: {
        Modal,
        ModalPulverization,
        ModalEditOneHarvest,
        ModalEditOnePulverization,
        ModalEditOneFertilization,
        ModalEditOnePlanting,
        ModalEditOneOtherActivity,
        ModalEditOneSoilPreparation,
        ModalEditOneSeedTreatment,
        ModalHarvest,
        ModalPlanting,
        ModalSoilPreparation,
        ModalSeedTreatment,
        ModalFertilization,
        ModalOtherActivity
    },
    data() {
        return {
            loading: false,
            isLoadingDeactivate: false,
            showModalPlanting: false,
            showModalHarvest: false,
            showModalPulverization: false,
            showModalEditOnePlanting: false,
            showModalEditOnePulverization: false,
            showModalSoilPreparation: false,
            showModalSeedTreatment: false,
            showModalFertilization: false,
            showModalEditOneFertilization: false,
            showModalEditOneHarvest: false,
            showModalOtherActivity: false,
            showModalEditOneOtherActivity: false,
            showModalEditOneSoilPreparation: false,
            showModalEditOneSeedTreatment: false,
            harvestData: { _id: 1 },
            plantingData: { _id: 2 },
            pulverizationData: { _id: 3 },
            soilPreparationData: { _id: 4 },
            seedTreatmentData: { _id: 5 },
            fertilizationData: { _id: 6 },
            otherActivityData: { _id: 7 },
            updateOnlyOneActivity: false,
            updateOnlyOneActivityDate: ''
        };
    },
    computed: {
        ...mapGetters("users", ["profile", "getUsers"]),
        ...mapGetters("fields", ["allFields"]),
        ...mapGetters("unit-conversions", ["getUnitConversions"]),
        displayTextActivityFieldName() {
            const fieldName = this.returnIfExists(this.data, "planning.subarea.field.name")
            if (!fieldName) {
                return '-'
            }

            const fieldSize = this.returnIfExists(this.data, "planning.subarea.field.size.value")
            if (!fieldSize) {
                return fieldName
            }

            const fieldUnit = this.returnIfExists(this.data, "planning.subarea.field.size.unity")

            return `${fieldName} (${fieldSize} ${fieldUnit})`
        },
        displayTextActivitySubareaName() {
            const subareaName =  this.returnIfExists(this.data, "planning.subarea.seed");
            if (!subareaName) {
                return '-'
            }

            const unit = this.returnIfExists(this.data, "planning.subarea.field.size.unity");
            const area = this.returnIfExists(this.data, "planning.area");
            if (!area && area !== 0 || !unit) {
                return subareaName
            }

            return `${subareaName} (${area} ${unit})`
        },
        displayTextActivityDate() {
            const date = this.returnIfExists(this.event, "extendedProps.date.dateHour")
            if (!date) {
                return '-'
            }

            if (this.activityType == 'pulverization') {
                return this.formatDateTime(date)
            }

            return this.formatDate(date)
        },
        displayTextActivityTitle() {
            const activityTitle = this.returnIfExists(this.data, "planning.title")
            if (!activityTitle) {
                return this.activityTypeTranslated
            }

            return `${this.activityTypeTranslated} - ${activityTitle}`
        },
        displayTextActivitySeedToTreat() {
            const seedToTreatName = this.returnIfExists(this.data, "planning.seedToTreat.stockProduct.product.name")
            if (!seedToTreatName) {
                    return '-'
            }

            const seedToTreatQuantityValue = this.returnIfExists(this.data, "planning.seedToTreat.quantity.value")
            if (!seedToTreatQuantityValue) {
                return seedToTreatName
            }

            const seedToTreatQuantityUnitName = this.returnIfExists(this.data, "planning.seedToTreat.quantity.unit.name")
            if (!seedToTreatQuantityUnitName) {
                //return `${seedToTreatName} - ${this.formatNumber(seedToTreatQuantityValue)}`
                return `${seedToTreatName} - ${this.formatNumberWithThreeDecimalPlaces(seedToTreatQuantityValue)}`
            }

            //return `${seedToTreatName} - ${this.formatNumber(seedToTreatQuantityValue)} ${seedToTreatQuantityUnitName}`
            return `${seedToTreatName} - ${this.formatNumberWithThreeDecimalPlaces(seedToTreatQuantityValue)} ${seedToTreatQuantityUnitName}`
        },
        displayTextActivityExpectedProductivity() {
            const expectedProductivityValue = this.returnIfExists(this.data, "planning.expectedProductivity.value");
            if (!expectedProductivityValue) {
                return '-'
            }
            //const expectedProductivityValueFormatted = this.formatNumber(expectedProductivityValue);
            const expectedProductivityValueFormatted = this.formatNumberWithThreeDecimalPlaces(expectedProductivityValue);

            const expectedProductivityUnity = this.returnIfExists(this.data, "planning.expectedProductivity.unity");
            if (!expectedProductivityUnity) {
                return expectedProductivityValueFormatted
            }

            return `${expectedProductivityValueFormatted} ${expectedProductivityUnity}`
        },
        displayTextActivityProductivity() {
            const productivityValue = this.returnIfExists(this.data, "planning.productivity.value");
            if (!productivityValue) {
                return '-'
            }
            //const productivityValueFormatted = this.formatNumber(productivityValue);
            const productivityValueFormatted = this.formatNumberWithThreeDecimalPlaces(productivityValue);

            const productivityUnity = this.returnIfExists(this.data, "planning.productivity.unity");
            if (!productivityUnity) {
                return productivityValueFormatted
            }

            return `${productivityValueFormatted} ${productivityUnity}`
        },
        displayTextActivityProduction() {
            const productionValue = this.returnIfExists(this.data, "planning.production.value");
            if (!productionValue) {
                return '-'
            }
            //const productionValueFormatted = this.formatNumber(productionValue);
            const productionValueFormatted = this.formatNumberWithThreeDecimalPlaces(productionValue);

            const productionUnity = this.returnIfExists(this.data, "planning.production.unity");
            if (!productionUnity) {
                return productionValueFormatted
            }

            return `${productionValueFormatted} ${productionUnity}`
        },
        displayTextActivityHumidityGrains() {
            const humidityGrainsValue = this.returnIfExists(this.data, "planning.humidityGrains.value")
            if (!humidityGrainsValue) {
                return '-'
            }

            //return this.formatNumber(humidityGrainsValue)
            return this.formatNumberWithThreeDecimalPlaces(humidityGrainsValue)
        },
        displayTextActivityFlowRate() {
            const flowRateValue = this.returnIfExists(this.data, "planning.flowRate.value")
            if (!flowRateValue) {
                return '-'
            }

            //return this.formatNumber(flowRateValue)
            return this.formatNumberWithThreeDecimalPlaces(flowRateValue)
        },
        displayTextActivityCulturePhase() {
            return this.returnIfExists(this.data, "planning.culturePhase.abbreviation") || '-'
        },
        displayTextActivityNotes() {
            return this.returnIfExists(this.data, "planning.notes") || "-"
        },
        displayArrayActivityStockProducts() {
            if (!this.hasStockProducts) {
                return []
            }

            return this.data.planning.stockProducts.map(stockProduct => ({
                _id: stockProduct._id,
                isApproved: stockProduct.approved,
                hasQuantityAvailableInStock: this.hasQuantityAvailableInStock(stockProduct),
                displayTextStockProduct: this.getDisplayTextStockProduct(stockProduct),
            }))
        },
        displayArrayActivityMachineries() {
            if (!this.hasMachineries) {
                return []
            }

            return this.data.planning.machineries.map(machinery => ({
                _id: machinery._id,
                displayTextMachinery: this.returnIfExists(machinery, "machinery.model") || ""
            }))
        },
        displayArrayActivityOperators() {
            if (!this.hasOperators) {
                return []
            }

            return this.data.planning.operators.map(operator => ({
                _id: operator._id,
                displayTextOperator: this.returnIfExists(operator, "operator.name") || ""
            }))
        },
        activityTypeTranslated() {
            if (this.activityType == "planting") {
                return "Plantio";
            }
            if (this.activityType == "harvest") {
                return "Colheita";
            }
            if (this.activityType == "pulverization") {
                return "Pulverização";
            }
            if (this.activityType == "soilPreparation") {
                return "Preparo de solo";
            }
            if (this.activityType == "seedTreatment") {
                return "Tratamento de sementes"
            }
            if (this.activityType == "fertilization") {
                return "Fertilização"
            }
            if (this.activityType == "otherActivity") {
                return "Outra atividade"
            }
        },
        activityIcon() {
            return {
                'icon-pulverization': this.activityType == 'pulverization',
                'icon-planting': this.activityType == 'planting',
                'icon-harvest': this.activityType == 'harvest',
                'icon-soil-preparation': this.activityType == 'soilPreparation',
                'icon-seed-treatment': this.activityType == 'seedTreatment',
                'icon-fertilization': this.activityType == 'fertilization',
                'icon-other-activity': this.activityType == 'otherActivity'
            }
        },
        activityType() {
            return this.returnIfExists(this.data, "planning.type") || "-"
        },
        isActivityDone() {
            return this.returnIfExists(this.data, "planning.activityDate.done");
        },
        hasStockProducts() {
            return !!this.returnIfExists(this.data, "planning.stockProducts.length")
        },
        hasMachineries() {
            return !!this.returnIfExists(this.data, "planning.machineries.length")
        },
        hasOperators() {
            return !!this.returnIfExists(this.data, "planning.operators.length")
        },
        isFarmerAdmin() {
            return this.profile.role && (this.profile.role.includes('farmer-admin') || this.profile.role.includes('admin'));
        }
    },
    methods: {
        ...mapActions("activities", [
            "completePlanting",
            "deactivatePlanting",
            "completeHarvest",
            "deactivateHarvest",
            "completePulverization",
            "deactivatePulverization",
            "completeSoilPreparation",
            "deactivateSoilPreparation",
            "completeSeedTreatment",
            "deactivateSeedTreatment",
            "completeFertilization",
            "deactivateFertilization",
            "completeOtherActivity",
            "deactivateOtherActivity",
        ]),
        getDisplayTextStockProduct(stockProduct) {
            const stockProductName = this.returnIfExists(stockProduct, "stockProduct.product.name")
            if (!stockProductName) {
                return '-'
            }

            const stockProductValue = this.returnIfExists(stockProduct, "quantity.value")
            if (!stockProductValue) {
                return stockProductName
            }
            const formattedStockProductValue = this.formatNumberWithThreeDecimalPlaces(stockProductValue)
            //const formattedStockProductValue = this.formatNumber(stockProductValue)
            const stockProductUnitName = this.returnIfExists(stockProduct, "quantity.unit.name")
            const stockProductDosageName = this.returnIfExists(stockProduct, "quantity.dosage.name")
            if (!stockProductUnitName || !stockProductDosageName) {
                return `${stockProductName} - ${formattedStockProductValue}`
            }

            return `${stockProductName} - ${formattedStockProductValue} ${stockProductUnitName}/${stockProductDosageName}`
        },
        hasQuantityAvailableInStock(stockProduct) {
            const totalQuantityToUse = this.calculateActivityProductTotalQuantityToUse(this.data.planning, stockProduct)
            const stockQuantityAvailable = this.returnIfExists(stockProduct, "stockProduct.quantity") || 0

            return totalQuantityToUse < stockQuantityAvailable
        },
        async updateOneActivity() {
            const activity = this.data.planning;
            this.updateOnlyOneActivity = true
            this.updateOnlyOneActivityDate = this.data.planning.activityDate

            if (this.activityType == "harvest") {
                this.harvestData = activity;
                this.openModalEditOneHarvest();
            } else if (this.activityType == "pulverization") {
                this.pulverizationData = activity;
                this.openModalEditOnePulverization();
            } else if (this.activityType == "planting") {
                this.plantingData = activity;
                this.openModalEditOnePlanting();
            } else if (this.activityType == "soilPreparation") {
                this.soilPreparationData = activity
                this.openModalEditOneSoilPreparation();
            } else if (this.activityType == "seedTreatment") {
                this.seedTreatmentData = activity
                this.openModalEditOneSeedTreatment();
            } else if (this.activityType == "fertilization") {
                this.fertilizationData = activity
                this.openModalEditOneFertilization();
            } else if (this.activityType == "otherActivity") {
                this.otherActivityData = activity
                this.openModalEditOneOtherActivity();
            }
        },
        async updateActivity() {
            const activity = this.data.planning;

            if (this.activityType == "harvest") {
                this.harvestData = activity;
                this.openModalHarvest();
            } else if (this.activityType == "pulverization") {
                this.pulverizationData = activity;
                this.openModalPulverization();
            } else if (this.activityType == "planting") {
                this.plantingData = activity;
                this.openModalPlanting();
            } else if (this.activityType == "soilPreparation") {
                this.soilPreparationData = activity
                this.openModalSoilPreparation();
            } else if (this.activityType == "seedTreatment") {
                this.seedTreatmentData = activity
                this.openModalSeedTreatment();
            } else if (this.activityType == "fertilization") {
                this.fertilizationData = activity
                this.openModalFertilization();
            } else if (this.activityType == "otherActivity") {
                this.otherActivityData = activity
                this.openModalOtherActivity();
            }
        },
        openModalHarvest() {
            this.showModalHarvest = true;
        },
        closeModalHarvest() {
            this.harvestData = { _id: 1 };
            this.showModalHarvest = false;
        },
        openModalEditOneHarvest() {
            this.showModalEditOneHarvest = true;
        },
        closeModalEditOneHarvest() {
            this.harvestData = { _id: 1 };
            this.showModalEditOneHarvest = false;
        },
        openModalPlanting() {
            this.showModalPlanting = true;
        },
        closeModalPlanting() {
            this.plantingData = { _id: 2 };
            this.showModalPlanting = false;
        },
        openModalEditOnePlanting() {
            this.showModalEditOnePlanting = true;
        },
        closeModalEditOnePlanting() {
            this.plantingData = { _id: 2 };
            this.showModalEditOnePlanting = false;
        },
        openModalPulverization() {
            this.showModalPulverization = true;
        },
        closeModalPulverization() {
            this.pulverizationData = { _id: 3 };
            this.showModalPulverization = false;
        },
        openModalEditOnePulverization() {
            this.showModalEditOnePulverization = true;
        },
        closeModalEditOnePulverization() {
            this.pulverizationData = { _id: 3 };
            this.showModalEditOnePulverization = false;
        },
        openModalSoilPreparation() {
            this.showModalSoilPreparation = true;
        },
        closeModalSoilPreparation() {
            this.soilPreparationData = { _id: 4 };
            this.showModalSoilPreparation = false;
        },
        openModalEditOneSoilPreparation() {
            this.showModalEditOneSoilPreparation = true;
        },
        closeModalEditOneSoilPreparation() {
            this.soilPreparationData = { _id: 4 };
            this.showModalEditOneSoilPreparation = false;
        },
        openModalSeedTreatment() {
            this.showModalSeedTreatment = true;
        },
        closeModalSeedTreatment() {
            this.seedTreatmentData = { _id: 5 };
            this.showModalSeedTreatment = false;
        },
        openModalEditOneSeedTreatment() {
            this.showModalEditOneSeedTreatment = true;
        },
        closeModalEditOneSeedTreatment() {
            this.seedTreatmentData = { _id: 5 };
            this.showModalEditOneSeedTreatment = false;
        },
        openModalFertilization() {
            this.showModalFertilization = true;
        },
        closeModalFertilization() {
            this.fertilizationData = { _id: 6 };
            this.showModalFertilization = false;
        },
        openModalEditOneFertilization() {
            this.showModalEditOneFertilization = true;
        },
        closeModalEditOneFertilization() {
            this.fertilizationData = { _id: 6 };
            this.showModalEditOneFertilization = false;
        },
        openModalOtherActivity() {
            this.showModalOtherActivity = true;
        },
        closeModalOtherActivity() {
            this.otherActivityData = { _id: 7 };
            this.showModalOtherActivity = false;
        },
        openModalEditOneOtherActivity() {
            this.showModalEditOneOtherActivity = true;
        },
        closeModalEditOneOtherActivity() {
            this.otherActivityData = { _id: 7 };
            this.showModalEditOneOtherActivity = false;
        },
        completeActivityByType(type) {
            const activityId = this.data.planning._id;
            const dateId = this.data.planning.activityDate._id;

            if (type == "harvest") {
                return this.completeHarvest({
                    harvestId: activityId,
                    dateId: dateId,
                });
            } else if (type == "pulverization") {
                return this.completePulverization({
                    pulverizationId: activityId,
                    dateId: dateId,
                });
            } else if (type == "planting") {
                return this.completePlanting({
                    plantingId: activityId,
                    dateId: dateId,
                });
            } else if (type == "soilPreparation") {
                return this.completeSoilPreparation({
                    soilPreparationId: activityId,
                    dateId: dateId
                })
            } else if (type == "seedTreatment") {
                return this.completeSeedTreatment({
                    seedTreatmentId: activityId,
                    dateId: dateId
                })
            } else if (type == "fertilization") {
                return this.completeFertilization({
                    fertilizationId: activityId,
                    dateId: dateId
                })
            } else if (type == "otherActivity") {
                return this.completeOtherActivity({
                    otherActivityId: activityId,
                    dateId: dateId
                })
            }
        },
        async completeActivity() {
            this.loading = true;
            const { type } = this.data.planning;
            await this.completeActivityByType(type)
                .then(() => {
                    this.$vToastify.success(
                        "Atividade concluída com sucesso",
                        "Atividade"
                    );
                    this.loading = false;
                    this.emitDoneAndClose();
                })
                .catch(() => {
                    this.$vToastify.error(
                        "Essa atividade nao pode ser concluida neste momento!",
                        "Atividade"
                    );
                    this.loading = false;
                });
        },
        deactivateActivityByType(type) {
            const activityId = this.data.planning._id;

            if (type == "harvest") {
                return this.deactivateHarvest({
                    harvestId: activityId
                });
            } else if (type == "pulverization") {
                return this.deactivatePulverization({
                    pulverizationId: activityId
                });
            } else if (type == "planting") {
                return this.deactivatePlanting({
                    plantingId: activityId
                });
            } else if (type == "soilPreparation") {
                return this.deactivateSoilPreparation({
                    soilPreparationId: activityId
                })
            } else if (type == "seedTreatment") {
                return this.deactivateSeedTreatment({
                    seedTreatmentId: activityId
                })
            } else if (type == "fertilization") {
                return this.deactivateFertilization({
                    fertilizationId: activityId
                })
            } else if (type == "otherActivity") {
                return this.deactivateOtherActivity({
                    otherActivityId: activityId
                })
            }
        },
        async deactivateActivity() {
            const activityDates = this.data.planning.date.map(date => this.formatDate(date.dateHour));

            const wantsToDeactivate = await this.$vToastify.prompt({
                body: `Deseja realmente desativar essa atividade? Essa atividade está presente nas seguintes datas: ${activityDates.join(', ')}.`,
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })

            if (!wantsToDeactivate) {
                return;
            }

            if (!this.isFarmerAdmin) {
                return this.$vToastify.error(
                    "É necessário permissão de admin para realizar essa ação.",
                    "Atividade"
                );
            }
            this.isLoadingDeactivate = true;

            const { type } = this.data.planning;

            await this.deactivateActivityByType(type)
                .then(response => {
                    this.isLoadingDeactivate = false;

                    if (response instanceof Error) {
                        return;
                    }

                    this.$vToastify.success(
                        "Atividade desativada com sucesso",
                        "Atividade"
                    );

                    this.emitDoneAndClose();
                })
        },
        getActivity(type) {
            if (type == "planting") {
                return "Plantio";
            } else if (type == "harvest") {
                return "Colheita";
            } else if (type == "pulverization") {
                return "Pulverização";
            } else if (type == "soilPreparation") {
                return "Preparo de solo";
            } else if (type == "seedTreatment") {
                return "Tratamento de sementes";
            } else if (type == "fertilization") {
                return "Fertilização";
            } else if (type == "otherActivity") {
                return "Outra atividade";
            }
        },
        closeAll() {
            this.closeModalHarvest();
            this.closeModalPlanting();
            this.closeModalPulverization();
            this.closeModalSoilPreparation();
            this.closeModalSeedTreatment();
            this.closeModalFertilization();
            this.closeModalOtherActivity();
            this.closeModalEditOnePulverization();
            this.closeModalEditOneFertilization();
            this.closeModalEditOneOtherActivity();
            this.closeModalEditOneHarvest();
            this.closeModalEditOnePlanting();
            this.closeModalEditOneSoilPreparation();
            this.closeModalEditOneSeedTreatment();
        },
        emitDoneAndClose() {
            this.closeAll();
            this.$emit("done");
            this.$emit("close");
        },
    },
};
</script>

<style scoped lang="sass">
.icon
    &-fields
        mask: url("/img/icons/svg/talhao.svg") no-repeat 100% 100%
        @include icon-mask-sm
        background-color: $color-blue
        margin-left: 10px

    &-product
        mask: url("/img/icons/svg/produto.svg") no-repeat 100% 100%
        @include icon-mask-sm
        background-color: $color-blue
        margin-left: 10px

    &-machinery
        mask: url("/img/icons/svg/trator.svg") no-repeat 100% 100%
        @include icon-mask-sm
        background-color: $color-blue
        margin-left: 10px

    &-pulverization
        mask: url("/img/icons/svg/pulverization.svg") no-repeat 100% 100%
        @include icon-mask-sm

    &-planting
        mask: url("/img/icons/svg/planting.svg") no-repeat 100% 100%
        @include icon-mask-sm

    &-harvest
        mask: url("/img/icons/svg/harvest.svg") no-repeat 100% 100%
        @include icon-mask-sm

    &-soil-preparation
        mask: url("/img/icons/svg/soil-preparation.svg") no-repeat 100% 100%
        @include icon-mask-sm

    &-seed-treatment
        mask: url("/img/icons/svg/seed-treatment.svg") no-repeat 100% 100%
        @include icon-mask-sm

    &-fertilization
        mask: url("/img/icons/svg/fertilization.svg") no-repeat 100% 100%
        @include icon-mask-sm

    &-other-activity
        mask: url("/img/icons/svg/other-activity.svg") no-repeat 100% 100%
        @include icon-mask-sm

.content
    display: flex
    flex-direction: column
    grid-gap: 20px

    .description
        font-size: 16px

    .loading
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        color: $color-blue

    .fields
        display: flex
        flex-wrap: wrap

        div
            justify-content: flex-start
            width: 160px
            padding: 6px

    .label-description
        margin-left: 6px
        font-size: 10px

    .box
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: flex-start
        flex-wrap: wrap
        width: 100%

    .box-c
        display: flex
        flex-direction: row
        height: 30px
        width: 180px
        margin-bottom: 10px

@media (max-width: 600px)
    .app-modal-footer
        flex-wrap: wrap

    .btn
        width: 100%


.btn--danger
    filter: none
    background: none
    color: $color-grey

    &:hover
        color: $color-red
</style>

<style scoped>
.card {
    display: flex;
    justify-content: center;
    min-height: 100px;
    border: none;
    border-radius: 0;
}

.color-1 {
    background: rgba(0, 0, 0, 0.02);
}

.color-2 {
    background: rgba(60, 123, 52, 0.07);
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 5px;
}
</style>

<style>
.vm--container .app-modal {
    min-height: 200px;
    min-width: 400px;
    max-width: 700px;
}

.vm--container .app-modal-body {
    width: 100%;
}

.vm--modal {
    background: none;
    border-radius: 15px;
}

.status-big {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    margin-right: 10px;
}

.group {
    display: flex;
    align-items: center;
}

.status-small {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
    display: inline-table;
    margin-top: 0;
    margin-bottom: 1rem;
}
</style>
