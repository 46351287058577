<template>
    <div>
        <div class="pragues-container">
            <div class="pragues-control">
                <div class="control-container">
                    <div class="control-container__item">
                        <date-picker class="date-picker" label="Data Inicial" :dataSent="interval.startDate"
                            @dateSelected="interval.startDate = $event.date">
                        </date-picker>
                        <date-picker class="date-picker" label="Data Final" :dataSent="interval.endDate"
                            @dateSelected="interval.endDate = $event.date">
                        </date-picker>
                    </div>
                    <div class="control-container__item">
                        <combo-box-fields style="width: 100%" classParameter="text-left "
                            @fieldSelected="selectedField = $event" title="Selecione o talhão"
                            msgFirstItem="Selecionar talhão" :listFieldsToChoose="allFields"></combo-box-fields>
                    </div>
                    <div class="control-container__item">
                        <CustomSelector style="width: 100%" classParameter="text-left " @choice="sampleType = $event"
                            title="Tipo de agente danoso" hint="Selecionar tipo" :choices="sampleChoices">
                        </CustomSelector>
                    </div>
                    <div class="control-container__item">
                        <button @click="filterMapData()" class="btn rounded btn-primary btn--flat" type="button"
                            aria-haspopup="true" aria-expanded="false">
                            <span v-if="loading" class="spinner-border" role="status"></span>
                            <span v-else>Filtrar no mapa</span>
                        </button>
                    </div>
                    <div class="control-container__item">
                        <button v-if="hasClusterSamples" class="btn rounded btn-primary--outlined btn--flat"
                            type="button" aria-haspopup="true" aria-expanded="false">
                            <download-excel :data="this.excelData"
                                :name="getPropertySelected.name + ' ' + new Date().toLocaleString()">
                                <span> <i class="fas fa-download"></i> Baixar planilha</span>
                            </download-excel>
                        </button>
                    </div>
                    <div class="control-container__item">
                        <button v-if="hasClusterSamples" @click="generateMonitoringReport()"
                            class="btn rounded btn-primary--outlined btn--flat" type="button" aria-haspopup="true"
                            aria-expanded="false">
                            <span v-if="isLoadingReport" class="spinner-border" role="status"></span>
                            <span v-else> <i class="fas fa-file"></i> Baixar relatório</span>
                        </button>
                    </div>
                    <div class="control-container__item">
                        <ListPrague :fields="selectedFieldsShow" :samples="dataPragues" :title="selectedSampleType"
                            :selectedField="selectedFieldShow">
                        </ListPrague>
                    </div>
                </div>
            </div>
            <div class="pragues-map">
                <div class="pragues-map">
                    <div id="map" class="map-area"></div>
                    <div class="layer-switch">
                        <select v-model="currentMapLayer">
                            <option value="bing">Bing</option>
                            <option value="google">Google</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "@/components/commons/DatePicker.vue";
import ComboBoxFields from "@/components/commons/ComboBoxFields.vue";
import ListPrague from "@/components/pragues/ListPrague.vue";
import CustomSelector from "@/components/commons/CustomSelector.vue"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BingMapPlugin from "@/utils/bingMapPlugin.js";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { ordeyByDecrescentDate } from "@/utils/orderByDate";
import { drawPolygons, createMarker } from "@/utils/drawPolygons";
import { mapActions, mapGetters } from "vuex";
import JsonExcel from "vue-json-excel";

export default {
    name: "Prague",
    components: {
        ComboBoxFields,
        CustomSelector,
        "download-excel": JsonExcel,
        ListPrague,
        DatePicker,
    },
    data() {
        return {
            loading: false,
            isLoadingReport: false,
            polygonsShow: [],
            selectedField: {
                fieldObject: "all"
            },
            selectedFieldShow: {
                fieldObject: "all"
            },
            sampleType: "Ver todos",
            selectedSampleType: "Ver todos",
            sampleChoices: ['Ver todos', 'Pragas', 'Doenças', 'Plantas daninhas'],
            clusterSamples: [],
            selectedPolygons: [],
            selectedPolygonsShow: [],
            selectedFieldsShow: [],
            dataPragues: [],
            zoom: 14,
            mapCenter: {
                latitude: 0,
                longitude: 0
            },
            interval: {
                startDate: this.initializeStartDate(),
                endDate: this.initializeEndDate(),
            },
            dateIsValid: false,
            payload: {
                propertyId: "",
                cropId: "",
                interval: {},
            },
            map: null,
            currentMapLayer: 'bing',
            bingLayer: null,
            googleLayer: null,
            mapUrls: {
                bing: {
                    url: `https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=${process.env.VUE_APP_BING_MAPS_KEY}`,
                    attribution: "Tiles © Bing Maps"

                },
                google: {
                    url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
                    attribution: "Tiles © Google",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                },
            },

        };
    },

    watch: {
        getError(to) {
            this.$vToastify.error(to, "Erro ao buscar pragas");
            this.clearError();
        },
        async getCropSelected() {
            this.clearAll();
            this.updatePayload();
            await this.getFieldsByPropertyByCrop(this.payload);
            this.updatePolygons();
            this.showPolygons();
            this.updateMapCenter();
            this.updateMap();
        },
        selectedField: function () {
            this.updatePolygons()
        },
        currentMapLayer(newVal) {
            if (this.map) {
                this.updateMapLayer(newVal);
            }
        },
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected"]),
        ...mapGetters("fields", ["allFields"]),
        ...mapGetters("pragues", ["getPragues", "getError"]),
        excelData() {
            const excelData = []
            const percentages = {
                disease: [
                    '0%',
                    '0,01% a 0,1%',
                    '0,1% a 0,2%',
                    '0,2% a 0,3%',
                    '0,3% a 0,4%',
                    '0,5% a 0,6%',
                    '0,7% a 2,0%',
                    '7,0%',
                    '18,0%',
                    '42,0%',
                    '78,5%'
                ],
                plant: [
                    '0%',
                    '1% a 5%',
                    '6% a 10%',
                    '11% a 15%',
                    '16% a 25%',
                    '26% a 50%',
                    '51% a 75%',
                    '76% a 100%'
                ]
            };

            this.clusterSamples.forEach((clusterSample) => {
                const { name, phase, controlLevel, unity } = clusterSample && clusterSample.sample && clusterSample.sample.evaluation ? clusterSample.sample.evaluation : {}
                const values = [name, phase, `${(controlLevel || controlLevel === "0") ? controlLevel : ''}${(controlLevel || controlLevel === "0") && unity ? (' ' + unity) : ''}`]
                const details = values.filter(value => value).join(" - ") || '-'

                excelData.push({
                    "Data": clusterSample.samplingDate ? this.formatDate(clusterSample.samplingDate) : "-",
                    "Talhão": clusterSample.subarea && clusterSample.subarea.field && clusterSample.subarea.field.name ? clusterSample.subarea.field.name : "-",
                    "Nome do Ponto": clusterSample.pointName ? clusterSample.pointName : "-",
                    "Latitude": clusterSample.coordinates && clusterSample.coordinates.latitude ? clusterSample.coordinates.latitude : "-",
                    "Longitude": clusterSample.coordinates && clusterSample.coordinates.longitude ? clusterSample.coordinates.longitude : "-",
                    "Monitor de campo": clusterSample.responsible ? clusterSample.responsible : "-",
                    "Doença": clusterSample.sample && clusterSample.sample.disease ? clusterSample.sample.disease.name : "-",
                    "Planta daninha": clusterSample.sample && clusterSample.sample.plant ? clusterSample.sample.plant.name : "-",
                    "Praga": clusterSample.sample && clusterSample.sample.prague ? clusterSample.sample.prague.name : "-",
                    "Detalhes": details,
                    "Pontuação": clusterSample.sample && !clusterSample.sample.prague && (clusterSample.sample.score || clusterSample.sample.score === 0) ? clusterSample.sample.score : "-",
                    "Índice(%)": clusterSample.sample && !clusterSample.sample.prague && (clusterSample.sample.score || clusterSample.sample.score === 0) ? percentages[clusterSample.sample.plant ? 'plant' : 'disease'][clusterSample.sample.score] : "-",
                    "Número de Controle": clusterSample.sample && clusterSample.sample.evaluation && (clusterSample.sample.evaluation.controlLevel || clusterSample.sample.evaluation.controlLevel === "0") ? clusterSample.sample.evaluation.controlLevel : "-",
                    "Quantidade": clusterSample.sample && clusterSample.sample.prague && (clusterSample.sample.amountOfPestsFound || clusterSample.sample.amountOfPestsFound === 0) ? clusterSample.sample.amountOfPestsFound : "-",
                    "Plantas analisadas": clusterSample.sample && clusterSample.sample.prague && (clusterSample.sample.analyzedPlants || clusterSample.sample.analyzedPlants === 0) ? clusterSample.sample.analyzedPlants : "-",
                    "Plantas atacadas": clusterSample.sample && clusterSample.sample.prague && (clusterSample.sample.attackedPlants || clusterSample.sample.attackedPlants === 0) ? clusterSample.sample.attackedPlants : "-",
                    "Estádio da Cultura": clusterSample.cyclePhase ? clusterSample.cyclePhase : "-",
                    "Observações": clusterSample.notes ? clusterSample.notes : "-"
                })
            })

            return excelData
        },
        hasClusterSamples() {
            return this.clusterSamples.length > 0;
        }
    },
    async mounted() {
        this.clearAll();
        this.updatePayload()
        await this.getFieldsByPropertyByCrop(this.payload);
        this.updatePolygons();
        this.showPolygons();
        this.updateMapCenter();
        this.initializeMap();
        this.updateMap();
    },
    methods: {
        ...mapActions("pragues", [
            "listAllSamples",
            "listSamplesByInterval",
            "getSamplesByPropertyByCropByPeriod",
            "clearError",
        ]),
        ...mapActions("fields", [
            "getFieldsByPropertyByCrop"
        ]),
        ...mapActions("property", ["listFields"]),
        ...mapActions("reports", [
            "findMonitoringReport"
        ]),

        initializeMap() {
            this.map = L.map('map', {
                center: [this.mapCenter.latitude, this.mapCenter.longitude],
                zoom: this.zoom,
                zoomControl: false
            });

            this.bingLayer = new BingMapPlugin({
                bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                imagerySet: 'Aerial',
            });

            this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                attribution: this.mapUrls.google.attribution,
                subdomains: this.mapUrls.google.subdomains,
                maxZoom: 19
            });

            this.updateMapLayer(this.currentMapLayer);

            L.control.zoom({ position: 'topleft' }).addTo(this.map);
        },



        updateMapLayer(newLayer) {
            if (!this.bingLayer || !this.googleLayer) {
                this.bingLayer = new BingMapPlugin({
                    bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                    imagerySet: 'Aerial',
                });

                this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                    attribution: this.mapUrls.google.attribution,
                    subdomains: this.mapUrls.google.subdomains,
                    maxZoom: 19
                });
            }

            if (this.map) {

                if (this.map.hasLayer(this.bingLayer)) {
                    this.map.removeLayer(this.bingLayer);
                }
                if (this.map.hasLayer(this.googleLayer)) {
                    this.map.removeLayer(this.googleLayer);
                }

                if (newLayer === "bing") {
                    this.map.addLayer(this.bingLayer);
                } else if (newLayer === "google") {
                    this.map.addLayer(this.googleLayer);
                }
            }
        },

        updateMap() {
            this.map.eachLayer(layer => {
                if (!(layer instanceof L.TileLayer)) {
                    this.map.removeLayer(layer);
                }
            });

            var markers = L.markerClusterGroup({
                maxClusterRadius: 15
            });

            this.clusterSamples.forEach((sample) => {
                var marker = L.marker([
                    sample.coordinates.latitude,
                    sample.coordinates.longitude
                ], {
                    icon: sample.icon
                });

                marker.bindPopup(sample.text, {
                    autoClose: false,
                    closeOnClick: false
                });

                markers.addLayer(marker);
            });

            this.map.addLayer(markers);

            this.selectedPolygons.forEach(polygonData => {
                L.polygon(polygonData.latlngs, {
                    color: polygonData.color,
                    fillColor: polygonData.fillColor
                }).bindPopup(polygonData.text).addTo(this.map);
            });
        },

        updateMapCenter() {
            this.mapCenter = { latitude: 0, longitude: 0 }

            if (!this.selectedAllFields()) {
                const field = this.selectedField.fieldObject
                this.mapCenter.latitude = this.latitudeCenter(field);
                this.mapCenter.longitude = this.longitudeCenter(field);
            }

            if (this.mapCenter.latitude == 0 || this.mapCenter.longitude == 0) {
                const { latitude, longitude } = this.getPropertySelected
                this.mapCenter = latitude && !isNaN(latitude) && longitude && !isNaN(longitude) ?
                    { latitude, longitude } :
                    { latitude: 0, longitude: 0 }
            }

            if (this.map) {
                this.map.setView([this.mapCenter.latitude, this.mapCenter.longitude], 13);
            }
        },
        clearAll() {
            this.clusterSamples = []
            this.selectedPolygons = []
            this.selectedPolygonsShow = []
            this.selectedFieldsShow = []
            this.sampleChoices = ['Ver todos', 'Pragas', 'Doenças', 'Plantas daninhas']
            this.selectedSampleType = "Ver todos"
            this.sampleType = "Ver todos"
            this.selectedField = {
                fieldObject: "all"
            }
            this.selectedFieldShow = {
                fieldObject: "all"
            }
            this.dataPragues = []
            this.interval.startDate = this.initializeStartDate()
            this.interval.endDate = this.initializeEndDate()
        },
        updatePayload() {
            this.payload.propertyId = this.getPropertySelected._id;
            this.payload.cropId = this.getCropSelected._id;
            this.payload.interval = { ...this.interval };
        },
        updatePolygons() {
            const polygons = this.selectedAllFields() ? this.allFields : [this.selectedField.fieldObject]
            const crop = this.getCropSelected
            this.selectedPolygons = drawPolygons(polygons, crop);
        },
        selectedAllFields() {
            return this.selectedField.fieldObject == "all" || this.selectedField.fieldObject._id == undefined
        },
        latitudeCenter(field) {
            if (field.polygons && field.polygons.length > 0) {
                let latitude = 0
                for (let polygon of field.polygons) {
                    if (isNaN(polygon.latitude)) {
                        return 0
                    }
                    latitude += parseFloat(polygon.latitude)
                }
                return latitude / field.polygons.length
            }
            return 0
        },
        longitudeCenter(field) {
            if (field.polygons && field.polygons.length > 0) {
                let longitude = 0
                for (let polygon of field.polygons) {
                    if (isNaN(polygon.longitude)) {
                        return 0
                    }
                    longitude += parseFloat(polygon.longitude)
                }
                return longitude / field.polygons.length
            }
            return 0
        },
        dateToYMD(date) {
            var d = date.getDate();
            var m = date.getMonth() + 1;
            var y = date.getFullYear();
            return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        },
        initializeStartDate() {
            let d = new Date()
            d.setDate(d.getDate() - 30);
            return this.dateToYMD(d)
        },
        initializeEndDate() {
            let d = new Date()
            return this.dateToYMD(d)
        },
        validateInterval() {
            try {
                const { startDate, endDate } = this.interval
                if (startDate && endDate) {
                    if (new Date(startDate) <= new Date(endDate)) {
                        return true
                    } else {
                        this.$vToastify.error("A data inicial não pode ser maior que a final.", "Data");
                    }
                } else {
                    this.$vToastify.error("Escolha uma data válida nos dois campos.", "Data");
                }
                return false
            } catch (e) {
                this.$vToastify.error("Escolha uma data válida nos dois campos.", "Data");
                return false
            }
        },
        buildClusterText() {
            this.clusterSamples = []
            this.dataPragues.forEach((sample) => {

                const displaySample = {
                    responsible: sample.responsible,
                    coordinates: sample.coordinates,
                    pointName: sample.pointName,
                    notes: sample.notes,
                    samplingDate: sample.samplingDate,
                    cyclePhase: sample.cyclePhase,
                    subarea: sample.subarea,
                    icon: createMarker("#3333EE")
                }

                const mainText = `
                    <b>Talhão</b>: ${sample.subarea.field.name} <br>
                    <b>Nome do ponto</b>: ${sample.pointName} <br>
                    <b>Data</b>: ${this.formatDate(sample.samplingDate)} <br>
                    <b>Responsável</b>: ${sample.responsible} <br>
                    <b>Latitude</b>: ${sample.coordinates.latitude} <br>
                    <b>Longitude</b>: ${sample.coordinates.longitude} <br>
                `;

                sample.sampleDiseases.forEach((sampleDisease) => {
                    const copyDisplaySample = { ...displaySample }
                    copyDisplaySample.sample = sampleDisease
                    copyDisplaySample.icon = createMarker("#EE3333", "disease")
                    copyDisplaySample.text = mainText + `
                        <b>Doença</b>: ${sampleDisease.disease.name} - ${sampleDisease.evaluation.name} <br>
                    `;
                    if (this.selectedAllFields() || sample.subarea.field._id === this.selectedField.fieldObject._id) {
                        this.clusterSamples.push(copyDisplaySample)
                    }
                })

                sample.samplePragues.forEach((samplePrague) => {
                    const copyDisplaySample = { ...displaySample }
                    copyDisplaySample.sample = samplePrague
                    copyDisplaySample.icon = createMarker("#EE3333", "prague")
                    copyDisplaySample.text = mainText + `
                        <b>Praga</b>: ${samplePrague.prague.name} - ${samplePrague.evaluation.name} <br>
                        <b>Quantidade</b>: ${samplePrague.amountOfPestsFound} <br>
                        <b>Plantas analisadas</b>: ${samplePrague.analyzedPlants} <br>
                        <b>Plantas atacadas</b>: ${samplePrague.attackedPlants} <br>
                    `;
                    if (this.selectedAllFields() || sample.subarea.field._id === this.selectedField.fieldObject._id) {
                        this.clusterSamples.push(copyDisplaySample)
                    }
                })

                sample.samplePlants.forEach((samplePlant) => {
                    const copyDisplaySample = { ...displaySample }
                    copyDisplaySample.sample = samplePlant
                    copyDisplaySample.icon = createMarker("#EE3333", "plant")
                    copyDisplaySample.text = mainText + `
                        <b>Planta daninha</b>: ${samplePlant.plant.name} - ${samplePlant.evaluation.name} <br>
                    `;
                    if (this.selectedAllFields() || sample.subarea.field._id === this.selectedField.fieldObject._id) {
                        this.clusterSamples.push(copyDisplaySample)
                    }
                })

                const hasSampleDiseases = sample.sampleDiseases && sample.sampleDiseases.length > 0;
                const hasSamplePragues = sample.samplePragues && sample.samplePragues.length > 0;
                const hasSamplePlants = sample.samplePlants && sample.samplePlants.length > 0;
                if (!hasSampleDiseases && !hasSamplePragues && !hasSamplePlants) {
                    const copyDisplaySample = { ...displaySample };
                    copyDisplaySample.sample = null;
                    copyDisplaySample.text = mainText;

                    if (this.selectedAllFields() || sample.subarea.field._id === this.selectedField.fieldObject._id) {
                        this.clusterSamples.push(copyDisplaySample);
                    }
                }
            })
        },
        notUndefined(text) {
            return text && !text.includes("indefinido")
        },
        filterByType() {
            if (this.sampleType === "Pragas") {
                this.clearSampleDiseases(this.dataPragues)
                this.clearSamplePlants(this.dataPragues)
            } else if (this.sampleType === "Doenças") {
                this.clearSamplePlants(this.dataPragues)
                this.clearSamplePragues(this.dataPragues)
            } else if (this.sampleType === "Plantas daninhas") {
                this.clearSampleDiseases(this.dataPragues)
                this.clearSamplePragues(this.dataPragues)
            }
        },
        clearSampleDiseases(samples) {
            samples.forEach((sample) => {
                sample.sampleDiseases = []
            })
        },
        clearSamplePragues(samples) {
            samples.forEach((sample) => {
                sample.samplePragues = []
            })
        },
        clearSamplePlants(samples) {
            samples.forEach((sample) => {
                sample.samplePlants = []
            })
        },
        async showPolygons() {
            this.selectedPolygonsShow = [...this.selectedPolygons];
        },
        async filterMapData() {
            const validDate = this.validateInterval();
            if (validDate) {
                this.loading = true;
                this.updatePayload();
                await this.getSamplesByPropertyByCropByPeriod(this.payload);
                this.loading = false;
                this.dataPragues = ordeyByDecrescentDate(this.getPragues);
                this.filterByType();
                this.buildClusterText();
                this.updatePolygons();
                this.showPolygons();
                this.updateMap();
                this.updateMapCenter();
                this.selectedSampleType = this.sampleType;
                this.selectedFieldShow = this.selectedField;
                this.selectedFieldsShow = [...this.allFields];
            }
        },
        async generateMonitoringReport() {
            const { propertyId, cropId, interval } = this.payload;
            const { startDate, endDate } = interval;

            const fieldIdList = this.selectedAllFields()
                ? this.allFields.map(field => field._id)
                : [this.selectedField.fieldObject._id]


            const sampleTypeTranslate = {
                'Pragas': 'prague',
                'Doenças': 'disease',
                'Plantas daninhas': 'plant'
            }

            const pestilenceTypes = this.sampleType === 'Ver todos'
                ? ['prague', 'disease', 'plant']
                : [sampleTypeTranslate[this.sampleType]]

            this.isLoadingReport = true;

            const response = await this.findMonitoringReport({
                propertyId,
                cropId,
                startDate,
                endDate,
                fieldIdList,
                pestilenceTypes,
                includePhotos: false
            });

            if (response.status === 200 && response.data.pdf) {
                this.$vToastify.success('PDF gerado com sucesso!')
                this.goToPage(response.data.pdf)
            }

            this.isLoadingReport = false;
        },
        goToPage(url) {
            window.open(url, '_blank')
        }
    }
};
</script>

<style scoped lang="sass">
@import "~leaflet.markercluster/dist/MarkerCluster.css"
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css"

.pragues
    &-container
        display: flex
        grid-gap: 10px
        height: 100%
        overflow: hidden

    &-control
        width: 350px
        height: 100%

        .control
            &-tabs
                margin-bottom: 8px

            &-container
                display: flex
                flex-direction: column

                &__item
                    display: flex
                    grid-gap: 10px
                    justify-content: center
                    padding: 0
                    margin-bottom: 8px

                    button
                        height: 50px
                        width: 100%

    &-map
        width: 100%

.map-area
    height: calc(100vh - 82px - 20px)

.date-picker
    margin: 0
    padding: 0
    width: 166px

@media (max-width: 992px)
    .pragues
        &-container
            flex-direction: column
            overflow: auto

        &-control
            width: 100%

    .date-picker
        width: calc(100% / 2)

@media (max-width: 1200px)
    .pragues-control
        .control-container__item
            flex-direction: column

    .date-picker
        width: 100%

@media (max-height: 990px)
    .pragues-control
        .control-container
            height: 100%
            overflow: auto

.layer-switch 
    position: absolute
    bottom: 10px
    left: 500px
    z-index: 1000
    background-color: white
    padding: 5px
    border-radius: 5px

</style>

<style lang="sass">
.leaflet-control-container
    .leaflet-top
        z-index: 400 !important

</style>