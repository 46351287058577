import API from "./API";

export default {
  getAll() {
    return API.get("crops");
  },

  get(id) {
    return API.get(`crops/${id}`);
  },

  cultures() {
    return API.get(`cultures`);
  },

  cloneCrop(cropId) {
    return API.post(`crops/clone/${cropId}`);
  },

  getFieldsByCropAndProperty(id) {
    return API.get(`crops/${id}/fields`);
  },

  getCropsReport(propertyId, cropId){
    return API.get(`reports/crops/properties/${propertyId}/crops/${cropId}`)
  },

  post(data) {
    return API.post("crops", data);
  },

  update(data) {
    return API.put(`crops/${data._id}`, data);
  },

  delete(id) {
    return API.delete(`crops/${id}`);
  },
};
