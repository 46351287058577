<template>
        <div id="map" style="height: 85vh;">
            <div class="layer-switch">
            <select v-model="currentMapLayer">
                <option value="bing">Bing</option>
                <option value="google">Google</option>
            </select>
        </div>
        </div>
   
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BingMapPlugin from "@/utils/bingMapPlugin.js"; 
import { drawPolygon } from "@/utils/drawPolygons";

export default {
    name: "FieldOverviewMap",
    props: {
        field: {
            type: Object,
        },
    },
    data() {
        return {
            map: null,
            polygonLayer: null,
            latitude: "",
            longitude: "",
            zoom: 14,
            currentMapLayer: 'bing',
            bingLayer: null,
            googleLayer: null,
            mapUrls: {
                bing: {
                    url: `https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=${process.env.VUE_APP_BING_MAPS_KEY}`,
                    attribution: "Tiles © Bing Maps"

                },
                google: {
                    url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
                    attribution: "Tiles © Google",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                },
            },
        };
    },
    mounted() {
        this.initializeMap();
    },
    methods: {
        initializeMap() {
            if (this.field.polygons && this.field.polygons.length > 0) {
                this.latitude = this.field.polygons[0].latitude;
                this.longitude = this.field.polygons[0].longitude;
            } else {
                this.latitude = 0;
                this.longitude = 0;
            }

            this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom);

            this.bingLayer = new BingMapPlugin({
                bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                imagerySet: 'Aerial',
            });

            this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                attribution: this.mapUrls.google.attribution,
                subdomains: this.mapUrls.google.subdomains,
                maxZoom: 19
            });

            this.updateMapLayer(this.currentMapLayer);

            if (this.field.polygons && this.field.polygons.length > 0) {
                const polygonData = drawPolygon(this.field);
                this.polygonLayer = L.polygon(polygonData.latlngs, {
                    color: polygonData.color,
                    fillColor: polygonData.fillColor,
                }).addTo(this.map);
                this.polygonLayer.bindPopup(polygonData.text);
            }
        },
        updateMapLayer(newLayer) {
            if (!this.bingLayer || !this.googleLayer) {
                this.bingLayer = new BingMapPlugin({
                    bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                    imagerySet: 'Aerial',
                });

                this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                    attribution: this.mapUrls.google.attribution,
                    subdomains: this.mapUrls.google.subdomains,
                    maxZoom: 19
                });
            }

            if (this.map) {

                if (this.map.hasLayer(this.bingLayer)) {
                    this.map.removeLayer(this.bingLayer);
                }
                if (this.map.hasLayer(this.googleLayer)) {
                    this.map.removeLayer(this.googleLayer);
                }

                if (newLayer === "bing") {
                    this.map.addLayer(this.bingLayer);
                } else if (newLayer === "google") {
                    this.map.addLayer(this.googleLayer);
                }
            }
        },
    },
    watch: {
        field() {
            if (this.field.polygons && this.field.polygons.length > 0) {
                this.latitude = this.field.polygons[0].latitude;
                this.longitude = this.field.polygons[0].longitude;
                this.map.setView([this.latitude, this.longitude], this.zoom);

                if (this.polygonLayer) {
                    this.polygonLayer.remove();
                }

                const polygonData = drawPolygon(this.field);
                this.polygonLayer = L.polygon(polygonData.latlngs, {
                    color: polygonData.color,
                    fillColor: polygonData.fillColor,
                }).addTo(this.map);
                this.polygonLayer.bindPopup(polygonData.text);
            }
        },
        currentMapLayer(newVal) {
            if (this.map) {
                this.updateMapLayer(newVal);
            }
        },
    }
};
</script>

<style>
.layer-switch {
  position: absolute;
  bottom: 25px;
  left: 50px;
  z-index: 1000;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}
.map-container {
    position: relative;
}
</style>