var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{class:{
    'alert alert-secondary': _vm.notification.read === false,
    'alert alert': _vm.notification.read === true,
  }},[_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.notification.createdAt)))]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.notification.title))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.notification.text))]),_c('br'),(_vm.notification.title === 'Previsão do tempo')?_c('router-link',{staticStyle:{"color":"#619b34","text-decoration":"none"},attrs:{"tag":"a","to":"/tempo"}},[_vm._v("Ver previsão"+_vm._s(" "))]):_vm._e(),(
        _vm.notification.title === 'Atividade Agendada' ||
        _vm.notification.title === 'Atividades realizadas e atrasadas'
      )?_c('router-link',{staticStyle:{"color":"#619b34","text-decoration":"none"},attrs:{"tag":"a","to":"/planejamento"}},[_vm._v("Ver planejamento"+_vm._s(" "))]):_vm._e(),(_vm.notification.title === 'Relatório NDVI')?_c('router-link',{staticStyle:{"color":"#619b34","text-decoration":"none"},attrs:{"tag":"a","to":"/relatorio/ndvi"}},[_vm._v("Ver relatórios NDVI"+_vm._s(" "))]):_vm._e(),_c('i',{staticClass:"fas fa-arrow-right",staticStyle:{"color":"#619b34","text-decoration":"none"}})],1),(_vm.notification.read === true)?_c('td',{staticClass:"text-center"},[_c('a',{staticClass:"btn-default",attrs:{"href":"#"},on:{"click":function($event){return _vm.markNotification(_vm.notification)}}},[_c('i',{staticClass:"fas fa-check-double"}),_vm._v(" Lida ")])]):_c('td',{staticClass:"text-center"},[_c('a',{staticClass:"btn-default",attrs:{"href":"#"},on:{"click":function($event){return _vm.markNotification(_vm.notification)}}},[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Marcar Lida ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }