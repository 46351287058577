import { render, staticRenderFns } from "./Movement.vue?vue&type=template&id=21d87667&scoped=true"
import script from "./Movement.vue?vue&type=script&lang=js"
export * from "./Movement.vue?vue&type=script&lang=js"
import style0 from "./Movement.vue?vue&type=style&index=0&id=21d87667&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21d87667",
  null
  
)

export default component.exports