<template>
    <div class="crops-container">
        <div class="crops-container__header">
            <span class="title">Safras</span>
            <div class="actions">
                <div class="search">
                    <div class="search-container">
                        <input
                            class="search-container__input app-input"
                            placeholder="Pesquise uma safra"
                            v-model="cropToSearch"
                            type="text"
                        />
                        <button class="search-container__clear clickable" @click="cleanFilterCrops"
                                v-show="cropToSearch.length > 0">
                            <i class="fal fa-times-circle"/>
                        </button>
                        <div class="search-container__search">
                            <i class="fas fa-search"/>
                        </div>
                    </div>
                </div>
                <div class="actions_crop_btns">
                    <button v-if="!seeDeactivatedCrops" class="btn btn-primary btn--flat" @click="openDeactivatedCrop">
                        <span>Ver desativadas</span>
                    </button>
                    <button v-else class="btn btn-primary btn--flat" @click="openActivatedCrop">
                        <span>Ver safras ativas</span>
                    </button>
                    <button class="btn btn-primary btn--flat" @click="openModalNewCrop">
                        <span>Nova safra</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loading && this.getCropsDeactivated.length > 0 && seeDeactivatedCrops === true" class="crops-container__content">
            <table class="crops-table">
                <tr>
                    <th>Nome</th>
                    <th>Cultura</th>
                    <th>Data inicial</th>
                    <th>Data final</th>
                    <th class="crops-table-header__actions">Ações</th>
                </tr>
                <tr v-for="(crop, i) in this.getCropsDeactivated" :key="i">
                    <td>{{ crop.name }}</td>
                    <td>{{ cultures.filter((e) => e._id == crop.culture)[0].name }}</td>
                    <td>{{ crop.startDate | formatDate }}</td>
                    <td>{{ crop.endDate | formatDate }}</td>
                    <td>
                        <div class="crops-table-content__actions">
                            <button @click="activateCrop(crop)" class="btn btn--flat mx-auto">
                                <i class="far fa-check"/>
                                <span>Ativar</span>
                            </button>
                            <button @click="openModalEditCrop(crop)" class="btn btn--flat mx-auto">
                                <i class="far fa-edit"/>
                                <span>Editar</span>
                            </button>
                            <button @click="openModalSubarea(crop)" class="btn btn--flat mx-auto">
                                <i class="fas fa-leaf"/>
                                <span>Cultivares</span>
                            </button>
                            <button @click="cloneCropAction(crop)" class="btn btn--flat mx-auto">
                                <span v-show="cloningCrop == crop._id" class="spinner-border spinner-border-sm"/>
                                <i v-show="cloningCrop != crop._id" class="fas fa-clone"></i>
                                <span v-show="cloningCrop != crop._id">Clonar</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="!loading && this.getCropsDeactivated.length === 0 && seeDeactivatedCrops === true" class="crops-container__content">
            <table class="crops-table">
                <tr>
                    <th>Nome</th>
                    <th>Subárea (cultivares)</th>
                    <th>Tamanho (ha)</th>
                    <th class="crops-table-header__actions">Ações</th>
                </tr>
            </table>
            <div class="text-center p-2" style="background-color: #f5f5f5">
                <span class="mx-auto">Não possui dados</span>
            </div>
        </div>
        <div v-if="!loading && cropsList.length > 0 && !seeDeactivatedCrops" class="crops-container__content">
            <table class="crops-table">
                <tr>
                    <th>Nome</th>
                    <th>Cultura</th>
                    <th>Data inicial</th>
                    <th>Data final</th>
                    <th class="crops-table-header__actions">Ações</th>
                </tr>
                <tr v-for="(crop, i) in cropsList" :key="i">
                    <td>{{ crop.name }}</td>
                    <td>{{ cultures.filter((e) => e._id == crop.culture)[0].name }}</td>
                    <td>{{ crop.startDate | formatDate }}</td>
                    <td>{{ crop.endDate | formatDate }}</td>
                    <td>
                        <div class="crops-table-content__actions">
                            <button @click="deactivateCrop(crop)" class="btn btn--flat mx-auto">
                                <i class="far fa-archive"/>
                                <span>Desativar</span>
                            </button>
                            <button @click="openModalEditCrop(crop)" class="btn btn--flat mx-auto">
                                <i class="far fa-edit"/>
                                <span>Editar</span>
                            </button>
                            <button @click="openModalSubarea(crop)" class="btn btn--flat mx-auto">
                                <i class="fas fa-leaf"/>
                                <span>Cultivares</span>
                            </button>
                            <button @click="cloneCropAction(crop)" class="btn btn--flat mx-auto">
                                <span v-show="cloningCrop == crop._id" class="spinner-border spinner-border-sm"/>
                                <i v-show="cloningCrop != crop._id" class="fas fa-clone"></i>
                                <span v-show="cloningCrop != crop._id">Clonar</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div v-else-if="loading" class="no-content">
            <span class="spinner-border"/>
        </div>
        <div v-if="!loading && cropsList.length === 0" class="crops-container__content">
            <table class="crops-table">
                <tr>
                    <th>Nome</th>
                    <th>Subárea (cultivares)</th>
                    <th>Tamanho (ha)</th>
                    <th class="crops-table-header__actions">Ações</th>
                </tr>
            </table>
            <div class="text-center p-2" style="background-color: #f5f5f5">
                <span class="mx-auto">Não possui dados</span>
            </div>
        </div>
        <modal-crop :open="modalCrop" @close="closeModalCrop" @save="loadCrops" :data="crop"/>
        <modal-subarea v-if="modalSubarea" :open="modalSubarea" @close="closeModalSubarea" :data="crop"/>
    </div>
</template>

<script>
import ModalCrop from "@/views/configurations/crops/components/ModalCrop.vue";
import ModalSubarea from "@/views/configurations/crops/components/ModalSubarea.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Crop',
    components: {
        ModalCrop,
        ModalSubarea
    },
    data() {
        return {
            loading: false,
            cloningCrop: null,
            modalCrop: false,
            modalSubarea: false,
            cropsList: [],
            cropToSearch: '',
            cultureToSearch: '',
            crop: {},
            seeDeactivatedCrops: false
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected", "getCrops", "getCropsDeactivated"]),
        ...mapGetters("crops", ["cultures"])
    },
    async mounted() {
        this.loading = true

        await this.getCultures();
        await this.loadCrops();

        this.loading = false
    },
    methods: {
        ...mapActions("crops", ["getCultures", "cloneCrop", "updateCrop"]),
        ...mapActions("property", ["listCrops"]),
        async activateCrop(crop){
            const wantsToActivate = await this.$vToastify.prompt({
                body: "Deseja ativar essa safra?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })    
            if(wantsToActivate){
                this.loading = true

                crop.active = true
                await this.updateCrop(crop)

                await this.getCultures();
                await this.loadCrops();

                this.loading = false
            }   
        },
        async deactivateCrop(crop){
            const wantsToDeactivate = await this.$vToastify.prompt({
                body: "Deseja desativar essa safra?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })    
            if(wantsToDeactivate){
                this.loading = true

                crop.active = false
                await this.updateCrop(crop)

                await this.getCultures();
                await this.loadCrops();

                this.loading = false
            }
        },
        openDeactivatedCrop() {
            this.seeDeactivatedCrops = true
        },
        openActivatedCrop() {
            this.seeDeactivatedCrops = false
        },
        openModalNewCrop() {
            this.crop = {}
            this.modalCrop = true
        },
        openModalEditCrop(crop) {
            this.crop = crop
            this.modalCrop = true
        },
        closeModalCrop() {
            this.modalCrop = false
        },
        openModalSubarea(crop) {
            this.crop = crop
            this.modalSubarea = true
        },
        closeModalSubarea() {
            this.modalSubarea = false
        },
        async loadCrops() {
            this.loading = true;
            await this.listCrops(this.getPropertySelected._id);
            this.loading = false;
        },
        async cloneCropAction(crop) {
            const cropId = crop._id
            const wantsToClone = await this.$vToastify.prompt({
                body: "Deseja clonar essa safra e seus cultivares?",
                title: "ATENÇÃO!",
                answers: { Sim: true, Não: false },
            })

            this.cloningCrop = crop._id
            if (wantsToClone) {
                await this.cloneCrop(cropId)
                await this.loadCrops()
            }
            this.cloningCrop = null
        },
        searchCropsWithFilter() {
            if (this.cropToSearch === "" && this.cultureToSearch === "") {
                return this.$vToastify.error("Insira o nome da safra ou tipo de cultura!", "Safra")
            }

            if (this.cultureToSearch === "") {
                let resultSelect = [];
                this.getCrops.filter(objInput => {
                    if (objInput.name.toUpperCase().includes(this.cropToSearch.toUpperCase())) {
                        resultSelect.push(objInput);
                    }
                });
                if (resultSelect.length === 0) {
                    return this.$vToastify.error("Não encontrado!", "Talhão");
                }
                return this.cropsList = resultSelect;
            }
            if (this.cropToSearch === "") {
                let resultInput = [];
                this.getCrops.filter(objSelect => {
                    if (objSelect.culture.toUpperCase() === this.cultureToSearch.toUpperCase()) {
                        resultInput.push(objSelect);
                    }
                });
                if (resultInput.length === 0) {
                    return this.$vToastify.error("Não encontrado!", "Talhão");
                }
                return this.cropsList = resultInput;
            }
            if (this.cropToSearch && this.cultureToSearch) {
                let imputAndFilterResult = [];
                this.getCrops.filter(objInpuAndFilter => {
                    if (objInpuAndFilter.name.toUpperCase() === this.cropToSearch.toUpperCase() &&
                        objInpuAndFilter.culture.toUpperCase() === this.cultureToSearch.toUpperCase()) {
                        imputAndFilterResult.push(objInpuAndFilter);
                    }
                });
                if (imputAndFilterResult.length === 0) {
                    return this.$vToastify.error("Não encontrado!", "Talhão");
                }
                return this.cropsList = imputAndFilterResult;
            }
        },
        async cleanFilterCrops() {
            this.cropToSearch = ''
            this.cultureToSearch = ''
            this.cropsList = this.getCrops
            this.cropsList.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
        },
        filterCrops(){
            this.cropsList = this.getCrops.filter(crop => {
                const cultureName = this.cultures.find(culture => culture._id == crop.culture).name
                return (
                    crop.name.toUpperCase().includes(this.cropToSearch.toUpperCase()) ||
                    cultureName.toUpperCase().includes(this.cropToSearch.toUpperCase())
                )
            })
        },
    },
    watch: {
        getCrops() {
            this.cropsList = [...this.getCrops]
            this.cropsList.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
        },
        cropToSearch() {
            this.filterCrops()
        },
        getCropSelected() {
            this.cropToSearch = ''
        }
    }
};
</script>

<style scoped lang="sass">
.actions_crop_btns
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 260px
.crops-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

        .search
            display: flex
            grid-gap: 10px

            &-container
                display: flex
                position: relative

                &__input
                    width: 300px

                &__search
                    position: absolute
                    right: 8px
                    top: 8px
                    border: 0
                    background: none

                &__clear
                    position: absolute
                    right: 36px
                    top: 7px
                    border: 0
                    background: none

    .crops-table
        width: 100%

        &-header__actions
            width: 10%
            text-align: center

        &-content__actions
            display: flex
            grid-gap: 6px
            align-items: center
            justify-content: center

            .btn
                background: none

                &:hover
                    background: $color-white-darken-1

                i
                    color: $color-blue

        th
            color: $color-blue
            padding: 0 10px
            font-size: 16px

        tr
            border-radius: 20px
            height: 70px

            &:nth-child(even)
                background-color: #f2f2f2

        td
            padding: 0 10px

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .crops-container__header .actions .search-container__input
        width: 200px

.spinner-border
    color: $theme-color-primary
</style>
