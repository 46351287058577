<template>
    <div class="culture-phase-selector">
        <app-selector
            class="culture-phase-selector__selector"
            :items="formattedCulturePhasesToSelector"
            title="Estádio da cultura"
            placeholder="Selecione o estádio da cultura"
            v-model="selectedCulturePhaseId"
        />
        <a
            class="culture-phase-selector__remove-icon"
            v-show="!!selectedCulturePhaseId"
            @click="unselectCulturePhase"
        >
            <i class="fas fa-times-circle"/>
        </a>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import AppSelector from '@/components/commons/AppSelector.vue'

export default {
    name: "CulturePhaseSelector",
    props: {
        value: {
            type: String
        }
    },
    components: {
        AppSelector
    },
    data() {
        return {
            selectedCulturePhaseId: null
        };
    },
    computed: {
        ...mapGetters("culture-phases", [ "getCulturePhases" ]),
        formattedCulturePhasesToSelector() {
            return this.getCulturePhases.map(culturePhase => ({
                _id: culturePhase._id,
                label: culturePhase.abbreviation,
                value: culturePhase._id
            }))
        }
    },
    watch: {
        value() {
            if (this.selectedCulturePhaseId != this.value) {
                this.selectedCulturePhaseId = this.value
            }
        },
        selectedCulturePhaseId() {
            this.$emit('input', this.selectedCulturePhaseId)
        }
    },
    methods: {
        unselectCulturePhase() {
            this.selectedCulturePhaseId = null
            this.value = null
            this.$emit('input', null)
        }
    }
};
</script>
<style lang="sass" scoped>
.culture-phase-selector
    display: flex
    margin-bottom: 20px

    &__selector
        flex: 1
        width: 150px

    &__remove-icon
        display: flex
        align-items: flex-end
        justify-content: center
        width: 40px
        padding-bottom: 12px

        &:hover
            cursor: pointer
</style>
