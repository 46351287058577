<template>
    <div>
        <modal :open="open" :loading="false" @close="$emit('close')">
            <div class="app-modal-header" slot="header">
                <span>{{ displayTextField }}</span>
            </div>
            <div class="app-modal-body" slot="body">
                <div class="content">
                    <div>
                        <label class="app-label" for="field">Nome do talhão</label>
                        <input class="app-input" id="field" placeholder="Nome do talhão" type="text"
                            v-model="field.name" />
                    </div>
                    <div class="flex-container">
                        <div>
                            <label class="app-label" for="area-type">Tipo de Área</label>
                            <select class="app-select" id="area-type" v-model="field.size.unity">
                                <option value="hectare" selected>Hectare</option>
                            </select>
                        </div>
                        <input-number id="area-value" label="Tamanho" placeholder="Ex: 250" :data="field.size.value"
                            @input="field.size.value = $event" />
                    </div>
                    <div class="leaflet-map-container">
                        <button class="leaflet-map-container__edit-button" @click="openModalFieldPolygonEditor">
                            {{ displayTextPolygon }}
                            <i class="fas fa-edit ml-2" />
                        </button>
                        <div id="map" class="leaflet-map-container__leaflet-map"></div>
                        <div class="layer-switch">
                            <select v-model="currentMapLayer">
                                <option value="bing">Bing</option>
                                <option value="google">Google</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer" slot="footer">
                <button class="btn btn-primary btn--flat" @click="saveField">
                    <span v-if="loading" class="spinner-border" role="status" />
                    <span v-else>
                        <i class="fa fa-check" />
                        Salvar
                    </span>
                </button>
            </div>
        </modal>
        <modal-field-polygon-editor v-if="showModalFieldPolygonEditor" :polygons="field.polygons"
            @close="closeModalFieldPolygonEditor" @polygon="field.polygons = $event"
            @area="field.size.value = $event" />
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BingMapPlugin from "@/utils/bingMapPlugin.js";
import { LMap, LPolygon, LPopup, LTileLayer } from "vue2-leaflet";
import Modal from '@/components/systemUI/Modal'
import InputNumber from '@/components/commons/InputNumber'
import { drawPolygon } from "@/utils/drawPolygons";
import { mapActions, mapGetters } from "vuex";
import ModalFieldPolygonEditor from './ModalFieldPolygonEditor';

import _ from 'lodash';

export default {
    name: 'ModalField',
    props: {
        data: Object,
        open: Boolean
    },
    components: {
        LMap,
        LTileLayer,
        LPolygon,
        LPopup,
        Modal,
        InputNumber,
        ModalFieldPolygonEditor
    },
    data() {
        return {
            loading: false,
            field: {
                name: "",
                size: {
                    value: "",
                    unity: "",
                },
                polygons: []
            },
            showModalFieldPolygonEditor: false,
            fieldAreaLimit: 300,
            map: undefined,
            currentMapLayer: 'bing',
            bingLayer: null,
            googleLayer: null,
            mapUrls: {
                bing: {
                    url: `https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=${process.env.VUE_APP_BING_MAPS_KEY}`,
                    attribution: "Tiles © Bing Maps"

                },
                google: {
                    url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
                    attribution: "Tiles © Google",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                },
            },
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected"]),
        polygonsShow() {
            return drawPolygon(this.field)
        },
        displayTextField() {
            return !this.data._id ? "Novo Talhão" : "Editar Talhão"
        },
        displayTextPolygon() {
            return !this.data._id ? "Novo Polígono" : "Editar Polígono"
        }
    },
    watch: {
        data() {
            this.copyData();
            if (this.map) {
                this.updateMapCenter();
            }
        },
        polygonsShow() {
            if (this.map) {
                this.updateMapCenter()
            }
        },
        currentMapLayer(newVal) {
            if (this.map) {
                this.updateMapLayer(newVal);
            }
        },
    },
    mounted() {
        this.copyData();
        this.$nextTick(() => {
            this.initializeMap();
        });
    },
    methods: {
        ...mapActions("fields", ["createField", "updateField"]),
        closeModalFieldPolygonEditor() {
            this.showModalFieldPolygonEditor = false
        },
        openModalFieldPolygonEditor() {
            this.showModalFieldPolygonEditor = true
        },
        initializeMap() {
            let center = [-11.8831661, -60.1766577]
            let zoom = 4
            if (this.field.polygons && this.field.polygons.length > 0) {
                center = [this.field.polygons[0].latitude, this.field.polygons[0].longitude]
                zoom = 16
            }
            this.map = L.map('map', { center, zoom: zoom });

            this.bingLayer = new BingMapPlugin({
                bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                imagerySet: 'Aerial',
            });

            this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                attribution: this.mapUrls.google.attribution,
                subdomains: this.mapUrls.google.subdomains,
                maxZoom: 19
            });

            this.updateMapLayer(this.currentMapLayer);

            this.updatePolygons()
        },
        getMapCenter() {
            if (!this.polygonsShow || this.polygonsShow.latlngs <= 3) {
                const { latitude, longitude } = this.getPropertyCoordinates(
                    this.getPropertySelected.coordinates.latitude,
                    this.getPropertySelected.coordinates.longitude
                )
                return {
                    latitude: latitude,
                    longitude: longitude
                }
            }

            const latitude = this.polygonsShow.latlngs[0][0];
            const longitude = this.polygonsShow.latlngs[0][1];
            return { latitude, longitude }
        },
        updateMapLayer(newLayer) {
            if (!this.bingLayer || !this.googleLayer) {
                this.bingLayer = new BingMapPlugin({
                    bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                    imagerySet: 'Aerial',
                });

                this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                    attribution: this.mapUrls.google.attribution,
                    subdomains: this.mapUrls.google.subdomains,
                    maxZoom: 19
                });
            }

            if (this.map) {

                if (this.map.hasLayer(this.bingLayer)) {
                    this.map.removeLayer(this.bingLayer);
                }
                if (this.map.hasLayer(this.googleLayer)) {
                    this.map.removeLayer(this.googleLayer);
                }

                if (newLayer === "bing") {
                    this.map.addLayer(this.bingLayer);
                } else if (newLayer === "google") {
                    this.map.addLayer(this.googleLayer);
                }
            }
        },
        updatePolygons() {
            if (!this.map) return;
            this.map.eachLayer(layer => {
                if (layer instanceof L.Polygon) {
                    this.map.removeLayer(layer);
                }
            });
            if (this.field.polygons.length > 0) {
                const polygonData = drawPolygon(this.field);
                L.polygon(polygonData.latlngs, {
                    color: polygonData.color,
                    fillColor: polygonData.fillColor,
                }).addTo(this.map).bindPopup(polygonData.text);
            }
        },
        updateMapCenter() {
            const center = this.getMapCenter();
            if (this.map && center.latitude && center.longitude) {
                this.map.setView([center.latitude, center.longitude], 14);
            }
            this.updatePolygons();
        },
        copyData() {
            this.field = _.cloneDeep(this.data)
            this.updatePolygons();
        },
        async saveField() {
            if (!this.field.name) {
                return this.$vToastify.error("Por favor insira o nome do talhão", "Nome")
            }
            if (!this.field.size.value) {
                return this.$vToastify.error("Por favor insira o tamanho do talhão", "Tamanho")
            }
            if (!this.field.size.unity) {
                return this.$vToastify.error("Por favor insira o tipo de área do talhão", "Área")
            }

            const field = {
                name: this.field.name,
                size: this.field.size,
                propertyId: this.getPropertySelected._id,
                polygons: this.field.polygons
            }
            const fieldId = this.field._id

            this.loading = true
            if (fieldId) {
                await this.updateField({ fieldId, field })
                    .then(response => {
                        return this.$vToastify.success(response.data.message, "Talhão");
                    })
                    .catch(error => {
                        this.loading = false
                        return this.$vToastify.error(error.response.data.message, "Talhão");
                    })
            } else {
                await this.createField(field)
                    .then(response => {
                        return this.$vToastify.success(response.data.message, "Talhão")
                    })
                    .catch(error => {
                        this.loading = false
                        return this.$vToastify.error(error.response.data.message, "Talhão");
                    })
            }
            this.loading = false

            this.$emit('close');
            this.$emit('inserted');
        }
    }
};
</script>

<style scoped lang="sass">
.content
    display: flex
    flex-direction: column
    grid-gap: 20px

    .description
        font-size: 16px

        .loading
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            color: $color-blue

        .fields
            display: flex
            flex-wrap: wrap

            div
                justify-content: flex-start
                width: 160px
                padding: 6px

        .label-description
            margin-left: 6px
            font-size: 10px

    .flex-container
        div
            width: 100%

    .field-table
        width: 100%

        &-header__actions
            width: 10%
            text-align: center

        &-content__actions
            display: flex
            grid-gap: 6px
            align-items: center
            justify-content: center

            .btn
                background: none

                &:hover
                    background: $color-white-darken-1

                i
                    color: $color-blue

        th
            color: $color-blue
            padding: 0 10px
            font-size: 16px

        tr
            border-radius: 20px
            height: 70px

            &:nth-child(even)
                bottom: $border

        td
            padding: 0 10px

.info-message
    color: $theme-color-primary
    font-size: 14px

.leaflet-map-container
    width: 100%
    position: relative

    &__leaflet-map
        width: 100%
        height: 300px

    &__edit-button
        position: absolute
        background: rgba(0, 0, 0, 0.6)
        color: rgba(255, 255, 255, 0.8) !important
        z-index: 9999
        right: 0
        top: 0
        padding: 8px
        border-width: 0

        &:hover
            cursor: pointer
            color: rgba(255, 255, 255, 1) !important

.layer-switch 
    position: absolute
    bottom: 10px
    left: 25px
    z-index: 1000
    background-color: white
    padding: 5px
    border-radius: 5px
</style>
